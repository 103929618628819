import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, NavLink } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import "./header.css";

const Navbar = ({ language, onLanguageChange }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const headerRef = useRef(null);
  const [toggleMenuIcon, setToggleMenuIcon] = useState(false);
  const [windowDimension, setWindowDimension] = useState(window.innerWidth);

  const localizedNavigations = {
    en: {
      navItems: [
        { id: 1, name: "Home", href: "/home" },
        { id: 2, name: "About Us", href: "/about" },
        { id: 3, name: "Main Attractions", href: "/main-attractions" },
        { id: 4, name: "Gallery", href: "/gallery" },
        { id: 5, name: "Contact Us", href: "/contact-us" },
      ],
      buyTickets: "Buy Tickets",
    },
    hi: {
      navItems: [
        { id: 1, name: "मुख्य पृष्ठ", href: "/home" },
        { id: 2, name: "हमारे बारे में", href: "/about" },
        { id: 3, name: "मुख्य आकर्षण", href: "/main-attractions" },
        { id: 4, name: "गैलरी", href: "/gallery" },
        { id: 5, name: "संपर्क करें", href: "/contact-us" },
      ],
      buyTickets: "टिकट खरीदें",
    },
  };

  const { navItems, buyTickets } = localizedNavigations[language];

  

  const detectWindowSize = () => {
    setWindowDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", detectWindowSize);
    return () => window.removeEventListener("resize", detectWindowSize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        headerRef.current &&
        !headerRef.current.contains(event.target) &&
        toggleMenuIcon
      ) {
        setToggleMenuIcon(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [toggleMenuIcon]);

  useEffect(() => {
    const header = document.querySelector(".main-header");
    const toggleClass = "is-sticky";

    const handleScroll = () => {
      if (window.scrollY > 150) {
        header.classList.add(toggleClass);
      } else {
        header.classList.remove(toggleClass);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleGoToBooking = () => {
    navigate("/booking");
  };

  const handleNavLinkClick = () => {
    setToggleMenuIcon(false);
  };

  return (
    <div className="main-header">
      <div className="container">
        <div
          className={`header-data row ${toggleMenuIcon ? "active" : ""}`}
          ref={headerRef}
        >
          <div className="brand">
            <Link to="/">
              <img src="/images/mahabharat-logo.svg" alt="Logo" />
            </Link>
          </div>
          <nav>
            <ul>
              {navItems.map((item) => (
                <li key={item.id}>
                  <NavLink
                    to={item.href}
                    className={({ isActive }) => (isActive ? "active" : "")}
                    onClick={handleNavLinkClick}
                  >
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </ul>
            <Form.Select
              id="language"
              aria-label="Language select"
              value={language}
              onChange={onLanguageChange}
            >
              <option value="en">English</option>
              <option value="hi">हिंदी</option>
            </Form.Select>
          </nav>
          {windowDimension <= 767 && (
            <button
              className="toggle-menu"
              onClick={() => setToggleMenuIcon(!toggleMenuIcon)}
            >
              {toggleMenuIcon ? (
                <svg
                  className="close-icon"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.16675 12.8333L7.00008 6.99996M7.00008 6.99996L12.8334 1.16663M7.00008 6.99996L12.8334 12.8333M7.00008 6.99996L1.16675 1.16663"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  className="open-icon"
                  width="26"
                  height="20"
                  viewBox="0 0 26 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5 1.66667H1.33325M24.6665 9.99999H1.33325M22 18.3333C12.8878 18.3333 10.4455 18.3333 1.33325 18.3333"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
          )}
          <button className="Buy-Tickets" onClick={handleGoToBooking}>
            {buyTickets}
            <span>
              <svg
                width="17"
                height="12"
                viewBox="0 0 17 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6L16 6M16 6L11 1M16 6L11 11"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
