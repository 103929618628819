import React from 'react';

function Blocka({ language }) {
  // Content for both English and Hindi
  const content = {
    en: [
      {
        title: "RECEPTION",
        image: "images/main-attractions/block-a/block-a-1.png",
        description: `The imposing Sudarshana Chakra, the legendary weapon of ancient tales and a symbol of the pursuit of righteousness leading to moksha or liberation, is suspended here from the ceiling to enchant you with its cosmic presence. Crafted with exquisite details by skilled artisans from Shantiniketan, West Bengal. It is adorned with thousands of meticulously placed stones and gems, offering you a glimpse of its celestial power. This intricately engineered divine discus captivates with its mesmerizing motion, brought to life through sophisticated mechanisms. Complementing this spectacle is a backdrop of cascading water, featuring a reusable and recyclable model that projects scenes from the Mahabharata, enhancing the overall experience.`,
        label: "Main Attraction",
      },
      {
        title: "BRIEFING AREA",
        image: "images/main-attractions/block-a/block-a-2.png",
        description: `The briefing area features a remarkable projection depicting a dialogue between Vedvyasa, known for
            compiling and transmitting Vedic knowledge, and Lord Brahma, discussing Vedvyasa's vision for creating
            the Mahabharata. In this divine discourse, Lord Ganesha assumes a pivotal role as the scribe, transcribing
            the epic as narrated by Vedvyasa.`,
      },
      {
        title: "REPLICA OF MAHABHARATA",
        image: "images/main-attractions/block-a/block-a-3.png",
        description: `The area holds a map of Bharatavarsha, strategically positioned amidst the battlefield of Kurukshetra. The
        map highlights the alliances formed by different Janapadas with either the Kauravas or the Pandavas
        during the epic Mahabharata war. Adjacent to this intricate cartographic depiction stands a captivating
        reproduction of a 700-year-old Mahabharata manuscript, gracefully displayed on a pedestal.
        Accompanying this centerpiece are replicas of manuscripts scripted in the ancient Spitzer script, currently
        housed in the British Museum, enhancing the historical ambiance of the setting`,
        label: "Main Attraction",
      },
      {
        title: "FAMILY TREE INSTALLATION",
        image: "images/main-attractions/block-a/block-a-4.png",
        description: `The splendid mural, crafted by artists from Kolkata, depicts a captivating glimpse of Rishi Vedvyasa
        recounting the Mahabharata. To add depth to the portrayal, an enchanting projection, accompanied by an
        audiovisual feature, showcases the lineage of the Chandravanshi rulers, tracing their ancestry back to
        Lord Brahma through King Budha, often referred to as the son of Soma or Chandra (the moon). It is
        through this lineage that the Kuru dynasty originated.`,
      },
      {
        title: "SHLOKA",
        image: "images/main-attractions/block-a/block-a-5.png",
        description: `This panel presents the most renowned verse from the Mahabharata, skillfully illustrating the profound
        narrative of the epic. It is elegantly portrayed on the wall, conveying the message that while individuals
        have the right to engage in actions, they are not entitled to the outcomes. This verse stems from a
        conversation between Lord Krishna and Arjun amidst the great war of the Mahabharata, when Arjun
        hesitated to fight due to the presence of his loved ones on the opposing side. Krishna imparts the wisdom
        that one should not be driven by the desire for results, but rather focus on fulfilling their duties without
        attachment to the outcome.`,
      },
      {
        title: "KURUVANSHA KA ITIHAS",
        image: "images/main-attractions/block-a/block-a-6.png",
        description: `The prepossessing projection screen displays the lineage of the Kuru dynasty, offering intricate insights
        into the illustrious figures of the Mahabharata. Beginning with Ganga's son, Bhishma, it traces the lineage
        through Dhritarashtra, Pandu, and their subsequent heirs. It showcases the succession dispute and jealousy
        between the brothers, magnified by the ambitions of their sons, ultimately leading to the cataclysmic war
        of Mahabharata.`,
      },
      {
        title: "48 KOSA YATRA",
        image: "images/main-attractions/block-a/block-a-7.png",
        description: `This installation captures the essence of the 48 kos yatra of the Kurukshetra region, encompassing 18
        temples and 4 Yakshas within the modern boundaries of Haryana. This area is designed to offer a
        comprehensive experience, combining elements such as an installation, a projection film, two murals
        depicting Yaksha and Yakshini crafted by artists from Kolkata, and an informative kiosk for you to gain a
        profound understanding of the narrative.`,
        label: "Main Attraction",
      },
      {
        title: "KRISHNA’S ODYSSEY",
        image: "images/main-attractions/block-a/block-a-8.png",
        description: `This alluring projection screen presents a film where Lord Krishna serves as the narrator, guiding you
        through the fascinating journey of the creation of the renowned Mahabharata, the 48 kos yatra, and his
        manifestation on the Kuru bhumi. Watching this screen immerses you in the teachings and stories shared
        by Sri Krishna, captivating you in a mesmerizing experience.`,
      },
      {
        title: "RAMP TO LIBRARY",
        image: "images/main-attractions/block-a/block-a-9.png",
        description: `This ramp here is embellished with suspended panels portraying the Dasha Avatar of Lord Vishnu,
        referring to the ten incarnations of the supreme deity in Hinduism, symbolizing his periodic descents to
        Earth to maintain harmony in the universe. Each of these manifestations possesses its own mythological
        significance and inspires devotion (bhakti) among his worshippers.`,
      },
      // Add other items here...
    ],
    hi: [
      {
        title: "आरंभ",
        image: "images/main-attractions/block-a/block-a-1.png",
        description: `भव्य सुदर्शन चक्र, अपनी ब्रह्मांडीय उपस्थिति से आपको आश्चर्यचकित करने के लिए आठ टन भारी इस चक्र को
        यहाँ छत से लटकाया गया है। पश्चिम बंगाल के कुशलकारीगरों द्वारा उत्कृष्ट विवरणों के साथ निर्मित यह हजारों
        सावधानीपूर्वक रखे गए रत्नों से सुशोभित है, जो आपको इसकी दिव्यशक्ति की एक झलक प्रदान करता है। जटिल
        रूप से निर्मित यह दिव्य चक्र जिसे परिष्कृत तंत्रों के माध्यम से जीवंत किया गया है, अपनी मंत्रमुग्ध करने वाली
        गति से आपको चकाचौंध कर देगा। इस दृश्य में बहते पानी की पृष्ठभूमि भी शामिल है, जिसमें एक पुनः प्रयोज्य
        और पुनर्नवीनीकरण नमूना है जो महाभारत के दृश्यों को पेश करता है, जिससे समग्र अनुभव बढ़ जाता है।
        भव्य सुदर्शन चक्र जो की प्राचीन कहानियों का एक प्रसिद्ध हथियार और मुक्ति की ओर ले जाने वाली धार्मिकता
        की खोज का प्रतीक है।8 टन भारी इस चक्र को यहां छत से लटकाया गया है। इसमें पश्चिम बंगाल के कुशल
        कारीगरों द्वारा रतन लगाए गए हैं जिससे इसमें दिव्यशक्ति की झलक मिलती है। इस चक्र को तंत्रों केमाध्यमसे
        आकर्षित बनाए के लिए तकनीकी रूप से तयार किया गया है। इसकी मंत्र मुग्ध गति यानी तेज़ गति आपको
        चौंकाती है और इस दृश्य को और आकर्षित बनाने के लिये बहता हुआ पानी को भी पे दर्शाया गया है जो नवीनीकरण
        का नमूना है। यह झरना ना केवल महाभारत के दृश्यों को पेश करता है।`,
        label: "मुख्य आकर्षण",
      },
      {
        title: "जमाव क्षेत्र",
        image: "images/main-attractions/block-a/block-a-2.png",
        description: `इस चित्र में भगवान ब्रह्मा जी और वेद व्यास जी के बीच में होने वाली बातचीत दर्शाई गई है। बातचीत का विषय
        वैदिक ज्ञान को इकठ्ठा करना और उसको दुनिया में फैलाने के लिए महाभारत जैसे बहुमूल्य काव्य का निर्माण
        करना है। इसी विषय में वेदव्यास जी ब्रह्मा जी सेयह चर्चा करते हैं। इस दिव्य प्रवचनमें, भगवान गणेश लेखक के
        रूप में एकमहत्वपूर्ण भूमिका निभाते हैं, और वेदव्यास द्वारा वर्णित महाकाव्य का प्रतिलेखन करते हैं।`,
      },
      {
        title: "महाभारत की प्रतिकृति",
        image: "images/main-attractions/block-a/block-a-3.png",
        description: `इस पैनल में कुरुक्षेत्र के युद्ध के मैदान को दिखाया गया है जिसमें भारतवर्ष का मानचित्र हैं। यह मानचित्र में
        महाभारत के समय में कौरवों और पांडवों के साथ जिस जिस क्षेत्र का गठबंधन हुआ था उन पर रोशनी डाली गई है
        और इसी मानचित्र के साथ में 700 साल पुरानी महाभारत की प्रति को आसन पर सुंदर रूप से सजाया गया है इसी के
        साथ स्पिटजर लिपि में लिखे दस्तावेजों की प्रतिकृतियां है जो वर्तमान में ब्रिटिश संग्रहालय में रखी गई है।`,
        label: "मुख्य आकर्षण",
      },
      {
        title: "वंश - वृक्ष",
        image: "images/main-attractions/block-a/block-a-4.png",
        description: `इस पैनल में कलकत्ता के कलाकारों द्वारा बनाए गए भित्ति चित्र मेंऋषि वेदव्यास जी की महाभारत का वर्णन करते
        हुए सुंदर झलक दिखाई गई है। चित्रण में गहराई जोड़ने के लिए, चित्र में चंद्र वंशी शासकों की वंशावली को भी
        दिखाया गया है जो राजा बुद्ध के माध्यम से होते हुए भगवान ब्रह्मा के वंश का पता लगाते हैं जिन्हे अक्सर चंद्रमा
        का पुत्र कहा गया हैऔर इसी से ही कुरु वंश की उत्पति हुई।`,
      },
      {
        title: "श्लोक",
        image: "images/main-attractions/block-a/block-a-5.png",
        description: `यह पैनल महाभारत के सबसे प्रसिद्ध श्लोक को प्रस्तुत करता है, जो महाभारत जैसे महाकाव्य की कथा को कुशल
        तरीके से दीवार पर चित्र के रूप में दिखाता है। यह श्लोक महाभारत के युद्ध के समय भगवान कृष्ण और अर्जुन के
        बीच हुई बातचीत से ही बना है।जब अर्जुन विरोधी पक्ष में अपने परिवारजनों के होने के कारण लड़ने से झिझक रहे थे
        तब कृष्ण ज्ञान देते हैं कि किसी को परिणाम यानि फल की इच्छा के बिना अपने कर्तव्यों को पूरा करने पर ध्यान
        देना चाहिए।`,
      },
      {
        title: "कुरुवंश का इतिहास",
        image: "images/main-attractions/block-a/block-a-6.png",
        description: `यहाँ कुरु राजवंश की वंशावली को प्रदर्शित किया गया है। गंगा के पुत्र भीष्म से शुरू होकर, यह धृतराष्ट्र, पांडु और
        उनके बाद के उत्तराधिकारियों के माध्यम से आगे बढ़ती है। यह भाइयों के बीच उत्तराधिकार विवाद और ईर्ष्या को
        दर्शाता है, जो उनके बेटों की महत्वाकांक्षाओं से बढ़ जाता है, जो अंततः महाभारत के विनाशकारी युद्धकी ओर ले
        जाता है।`,
      },
      {
        title: "48 कोस यात्रा",
        image: "images/main-attractions/block-a/block-a-7.png",
        description: `यह स्थापना कुरुक्षेत्र क्षेत्र की 48 कोस यात्रा के सार को दर्शाती है, जिसमें हरियाणा की आधुनिक सीमाओं के भीतर
        18 मंदिर और 4 यक्ष शामिल हैं। इस क्षेत्र को एक व्यापक अनुभव प्रदान करने के लिए बनाया गया है, जिसमें एक
        त्रिआयामी चित्र, एक प्रोजेक्शन फिल्म, कोलकाता के कलाकारों द्वारा तैयार किए गए यक्ष और यक्षिनी को दर्शाने
        वाले दो भित्ति चित्र और कथा की गहरी समझ प्राप्त करने के लिए एक सूचनात्मक कियोस्क जैसे तत्वों का संयोजन
        किया गया है।`,
        label: "मुख्य आकर्षण",
      },
      {
        title: "कृष्ण की गाथा",
        image: "images/main-attractions/block-a/block-a-8.png",
        description: `यह आकर्षक प्रोजेक्शन स्क्रीन एक फिल्म प्रस्तुतकरती है जहाँ भगवान कृष्ण कथाकार के रूप में कार्य करते हैं, जो
        आपको प्रसिद्ध महाभारत के निर्माण की आकर्षक यात्रा, 48 कोस यात्रा और कुरु भूमि पर उनकी अभिव्यक्ति के
        माध्यम से मार्गदर्शन करते हैं। इस पर्दे को देखने से आप श्री कृष्ण द्वारा साझा की गई शिक्षाओं और कहानियों में
        डूब जाते हैं, जो आपको एक मंत्रमुग्ध कर देने वाले अनुभव की और लेजाती है।`,
      },
      {
        title: "पुस्तकालय की और",
        image: "images/main-attractions/block-a/block-a-9.png",
        description: `यह रास्ता भगवान विष्णु के दशअवतार को चित्रित करने वाले निलंबित पैनलों से अलंकृत है, जो हिंदू धर्म में
        सर्वोच्च देवता के दस अवतारों का जिक्र करता है। भगवान विष्णु के यह अवतार ब्रह्मांड में सद्भाव बनाए रखनेके
        लिए पृथ्वी पर उनके आवधिक अवतरण का प्रतीक है। इन अभिव्यक्तियों में से प्रत्येक का अपना पौराणिक महत्व
        है और यह उनके उपासकों के बीच भक्ति को प्रेरित करता है।`,
      },
      // Add other items here...
    ],
  };

  return (
    <div className="main_attractions_list">
      {content[language].map((item, index) => (
        <div className="list_item" key={index}>
          <div className="attraction_image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="attranction_details">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            {item.label && <span className="main-attraction-label">{item.label}</span>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Blocka;
