import React from 'react';

function Blocke({ language }) {
  // Content for both English and Hindi
  const content = {
    en: [
      {
        title: "ARJUNA’S CHARIOT",
        image: "images/main-attractions/block-e/block-e-1.png",
        description: `This area offers an immersive experience of the teachings of the Bhagavad Gita through audio-visual
        elements. Adjacent to this installation awaits a lifelike chariot with four majestic hyper-realistic horses
        crafted by artists from Kolkata. You experience Arjuna's perspective on the battlefield by sitting in the
        chariot, symbolizing the journey of the human soul through life's struggles, including the essence of
        dharma (righteous duty), karma (action), and yoga (path to union with the divine). As you immerse
        yourself in this setting, you also absorb the wisdom of Sri Krishna and the Bhagavad Gita through a
        screen projection, depicting Krishna's role as Arjuna's charioteer and spiritual guide.`,
        label: "Main Attraction",
      },
      {
        title: "BELL INSTALLATION",
        image: "images/main-attractions/block-e/block-e-2.png",
        description: `This block enchants you through a captivating art installation that features thousands of small bells
        delicately strung together to form a stunning three-dimensional image of Lord Krishna playing the flute,
        designed by artists from Shantiniketan, West Bengal. The image of Krishna playing the flute symbolizes
        profound spiritual truths, including cosmic harmony, divine love, and inner peace, resonating deeply with
        all who encounter it. It invites you to immerse yourself in the transcendent beauty and spiritual
        significance of Lord Krishna's divine music.`,
        label: "Main Attraction",
      },
      {
        title: "GEETA PODS",
        image: "images/main-attractions/block-e/block-e-3.png",
        description: `Here in this area, multiple "Geeta pods" are assembled, each containing quizzes based on the shlokas and
        teachings of the Bhagavad Gita. You can actively engage with these interactive learning stations, testing
        your knowledge and receiving a printed certificate upon completion. These engaging activities offer the
        opportunity to not only learn about the timeless wisdom of the Bhagavad Gita but also to actively
        participate in its teachings, reinforcing understanding and fostering a deeper connection to its spiritual
        insights.`,
        label: "Main Attraction",
      },
      {
        title: "KRISHNA HOLOGRAM",
        image: "images/main-attractions/block-e/block-e-4.png",
        description: `This section provides you the experience of Vasudeva Krishna’s teachings through a large hologram in a
        pitch-black room presenting the holographic techniques to enrich your encounter. This immersive journey
        enables you to not just listen to Krishna's divine words but also perceive the symbolic depth of life's
        enduring truths. His teachings provide direction on ethical behavior, spiritual growth, and the quest for
        self-awareness, leading you on a transformative spiritual expedition towards self-discovery and
        enlightenment.`,
      },
      {
        title: "CHAPTERS SUMMARY",
        image: "images/main-attractions/block-e/block-e-5.png",
        description: `This area features splendidly large glass screens suspended from the ceiling, displaying teachings from
        the 18 chapters of the Mahabharata. You can sit alongside and engage with the translated shlokas,
        absorbing the visual presentation. These interactive displays allow you to immerse yourself in the
        profound symbolism and timeless wisdom encapsulated within the epic, fostering a deeper understanding
        and appreciation of its significance in Hindu mythology and spiritual philosophy. Additionally, a study
        zone is provided at the center of it with tablets for you to delve deeper into the teachings of the
        Mahabharata.`,
      },
      // Add other items here...
    ],
    hi: [
      {
        title: "अर्जुन का रथ",
        image: "images/main-attractions/block-e/block-e-1.png",
        description: `यह क्षेत्र श्रव्य-दृश्य तत्वों के माध्यम से भगवद गीता की शिक्षाओं का एक तल्लीन अनुभव प्रदान करता है। इस
        स्थापना के साथ में कोलकाता के कलाकारों द्वारा तैयार किए गए चार राजसी अति-यथार्थवादी घोड़ों के साथ एक
        जीवंत रथ का दृश्य है। आप रथ में बैठकर युद्ध के मैदान पर अर्जुन के दृष्टिकोण का अनुभव करते हैं, जो धर्म, कर्म
        और योग के सार सहित जीवन के संघर्षों के माध्यम से मानव आत्मा की यात्रा का प्रतीक है। जब आप इस परिवेश में
        खुद को विसर्जित करते हैं, तो आप अर्जुन के सारथी और आध्यात्मिक मार्गदर्शक के रूप में कृष्ण की भूमिका को
        देखते हुए एक चित्र प्रक्षेपण के माध्यम से श्री कृष्ण और भगवद गीता के ज्ञान को भी अनुभव करते हैं।`,
        label: "मुख्य आकर्षण",
      },
      {
        title: "घंटी स्थापना",
        image: "images/main-attractions/block-e/block-e-2.png",
        description: `यह कलाकृति आपको एक मनोरम कला स्थापना के माध्यम से मंत्रमुग्ध कर देती है जिसमें हजारों छोटी घंटियाँ
        एक साथ नाजुक तरीके से बंधी हुई हैं जो भगवान कृष्ण की बांसुरी बजाते हुए एक आश्चर्यजनक त्रि-आयामी छवि
        बनाती हैं, जिसे पश्चिम बंगाल के शांतिनिकेतन के कलाकारों द्वारा डिजाइन किया गया है। बांसुरी बजाने वाले
        कृष्ण की छवि गहन आध्यात्मिक सत्यों का प्रतीक है, जिसमें ब्रह्मांडीय सद्भाव, दिव्य प्रेम और आंतरिक शांति
        शामिल हैं, जो इसका सामना करने वाले सभी लोगों के साथ गहराई से प्रतिध्वनित होती है। यह आपको भगवान
        कृष्ण के दिव्य संगीत के दिव्य सौंदर्य और आध्यात्मिक महत्व में खुद को विसर्जित करने के लिए आमंत्रित करता है।`,
        label: "मुख्य आकर्षण",
      },
      {
        title: "गीता का ज्ञान",
        image: "images/main-attractions/block-e/block-e-3.png",
        description: `यहाँ इस क्षेत्र में, प्रश्नोत्तर के माध्यम से गीता के ज्ञान को बताया गया है। यहाँ भगवद्गीता के श्लोकों और शिक्षाओं
        पर आधारित प्रश्नोत्तरी है जिससे जुड़कर आप अपने ज्ञान का परीक्षण कर सकते हैं और पूरा होने पर एक मुद्रित
        प्रमाणपत्र प्राप्त कर सकते हैं। ये आकर्षक गतिविधियाँ न केवल भगवद गीता के कालातीत ज्ञान के बारे में जानने
        का अवसर प्रदान करती हैं, बल्कि इसकी शिक्षाओं में सक्रिय रूप से भाग लेने, समझ को मजबूत करने और इसकी
        आध्यात्मिक अंतर्दृष्टि के साथ एक गहरे संबंध को बढ़ावा देने का भी अवसर प्रदान करती हैं।`,
        label: "मुख्य आकर्षण",
      },
      {
        title: "वासुदेव कृष्ण",
        image: "images/main-attractions/block-e/block-e-4.png",
        description: `यह खंड आपको एक चित्र के माध्य मसे वासुदेव कृष्ण की शिक्षाओं का अनुभव प्रदान करता है। यह यात्रा आपको न
        केवल कृष्ण के दिव्य शब्दों को सुनने की अनुभूति देती है, बल्कि जीवन के स्थायी सत्यों की प्रतीकात्मक गहराई को
        भी समझने में सक्षम बनाती है। उनकी शिक्षाएँ नैतिक व्यवहार, आध्यात्मिक विकास और आत्म-जागरूकता की
        खोज पर दिशा प्रदान करती हैं, जो आपको आत्म-खोज और ज्ञान की दिशा में एक परिवर्तनकारी अभियान पर ले
        जाती हैं।`,
      },
      {
        title: "18वाँ अध्याय",
        image: "images/main-attractions/block-e/block-e-5.png",
        description: `महाभारत के 18 अध्यायका संवादात्मक प्रदर्शन श्लोकों के माध्यम से यहाँ इस क्षेत्र में आप अनुभव कर सकते है। ये
        आपको महाकाव्य के भीतर निहित गहन प्रतीकवाद और कालातीत ज्ञान में खुद को विसर्जित करने की अनुमति
        देते हैं, जिससे हिंदू पौराणिक कथाओं और आध्यात्मिक दर्शन में इसके महत्व की गहरी समझ और सराहना को
        बढ़ावा मिलता है। इसके अतिरिक्त, इसके केंद्र में एक अध्ययन क्षेत्र प्रदान किया गया है जिसमें आपको महाभारत
        की शिक्षाओं में गहराई सेजान सकते है।`,
      },
      // Add other items here...
    ],
  };

  return (
    <div className="main_attractions_list">
      {content[language].map((item, index) => (
        <div className="list_item" key={index}>
          <div className="attraction_image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="attranction_details">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            {item.label && <span className="main-attraction-label">{item.label}</span>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Blocke;
