import React from 'react';

function Blockd({ language }) {
  // Content for both English and Hindi
  const content = {
    en: [
      {
        title: "ASHWATHAMA & ATTACK ON PANDAVAS",
        image: "images/main-attractions/block-d/block-d-1.png",
        description: `Here stands an impressively pragmatic and monumental sculpture of Ashwathama, situated atop a mound
        of fallen soldiers from the Pandava camp, skillfully crafted by artists from Kolkata. This portrayal
        strikingly captures both his valor and his brutality. On the last day of Mahabharata war his actions, fueled
        by anger, resulted in the slaughter of the entire lineage of the Pandavas and an attempt to kill the unborn
        child of Abhimanyu. This representation serves as a poignant reminder of the devastating consequences of
        unbridled fury and the tragic toll of warfare. To further accentuate this portrayal, a sizable poster has been
        positioned behind it, enhancing the overall visual impact.`,
        label: "Main Attraction",
      },
      {
        title: "DHRITARASHTRA KI PAKAD",
        image: "images/main-attractions/block-d/block-d-2.png",
        description: `The projection film here unfolds within a meticulously recreated replica of Dhritarashtra's court, where
        the grief-stricken king mourning the loss of his sons, seeks vengeance against Bhima. This portrayal
        encapsulates you into the intensity of Dhritarashtra's emotions and his determination to exact revenge. His
        actions contribute to the perpetuation of the cycle of violence and retaliation that has plagued the Kuru
        dynasty, illustrating the dire consequences of unresolved conflicts and the leadership's failure to uphold
        principles of justice and reconciliation. Utilizing cutting-edge pixel technology, moving images of the
        Pandavas and Dhritarashtra are presented to enhance your visual experience, providing a deeper
        immersion into the narrative.`,
        label: "Main Attraction",
      },
      {
        title: "YUDHISTER’S CORONATION",
        image: "images/main-attractions/block-d/block-d-3.png",
        description: `In this area, you're enticed by the splendor of Hastinapur's court during Yudhishthira's coronation, where
        his majestic throne takes center stage. His coronation represents the triumph of truth and virtue over
        deceit and tyranny, signaling a new era of peace and prosperity for the land. It also symbolizes the
        culmination of the Pandavas' challenging journey and their ultimate victory in reclaiming their rightful
        inheritance. The coronation ceremony of Yudhishthira is a moment of celebration and unity, as allies and
        subjects alike come together to honor Pandavas leadership and pledge their loyalty to the new king.`,
        
      },
      {
        title: "SWARGALOKA YATRA",
        image: "images/main-attractions/block-d/block-d-4.png",
        description: `Here an elegantly crafted elongated ramp structure invites you to imagine ascending to Swarga-loka.
        Projected on a screen, the Pandavas and Draupadi embark on their quest for salvation, climbing Mount
        Meru on their journey to Swarga-loka. They encounter numerous tests and moral dilemmas, challenging
        their virtue, humility, and understanding of dharma. Despite the adversities, Yudhishthira remains
        unwavering in his commitment to righteousness. This journey symbolizes the culmination of
        Yudhishthira's upright life and his eventual reward for upholding dharma. Swarga signifies the celestial
        realm where the righteous receive their just rewards. Yudhishthira's entry into Swarga through a
        beautifully crafted Swarga-loka dwar or gate marks both the pinnacle of his virtuous life and a symbolic
        resolution of his earthly struggles and responsibilities.`,
        label: "Main Attraction",
      },
      {
        title: "GANDHARI SHRAAP",
        image: "images/main-attractions/block-d/block-d-5.png",
        description: `The descending elevation lets you encounter the scene where Gandhari, in a moment of anguish, curses
        Sri Krishna, foretelling the demise of his entire clan, the Yaduvanshis mirroring the fate of her own sons,
        the Kauravas. Gandhari's curse upon Krishna in the Mahabharata encapsulates the profound grief and rage
        of a mother who has witnessed the destruction of her lineage, challenging the notion of divine
        intervention and underscoring the universal principle of cause and effect. Symbolizing justice,
        accountability, and the cyclical nature of existence, her curse here highlights the irony of fate as even the
        wise and divine like Vasudeva Krishna is subject to its consequences.`,
      },
      {
        title: "DWARKA SUBMERGENCE",
        image: "images/main-attractions/block-d/block-d-5.png",
        description: `Through a combination of an immersive mural, wall panels, and advanced projections, you can here
        witness the poignant moment of Dwarka's submergence, the legendary city associated with Lord Krishna,
        as it disappears into the depths of the Arabian Sea. The prophecy of Dwarka's sinking, linked to
        Gandhari’s curse tied to the downfall of the Yadava dynasty, unfolds after Krishna's departure from the
        mortal realm, gradually engulfing the city under the waves. This event marks the conclusion of an era and
        the shift from the Dvapara Yuga to the Kali Yuga, signifying the end of Krishna's earthly endeavors and
        the dawn of a new epoch.`,
      },
      // Add other items here...
    ],
    hi: [
      {
        title: "अश्वत्थामा और पांडवों पर हमला",
        image: "images/main-attractions/block-d/block-d-1.png",
        description: `यहाँ अश्वत्थामा की एक प्रभावशाली स्मारकीय मूर्ति है, जो पांडव शिविर के शहीद सैनिकों के एक टीले के ऊपर
        स्थित है, जिसे कोलकाता के कलाकारों द्वारा कुशलता से तैयार किया गया है। यह चित्रण अश्वथामा की वीरता और
        क्रूरता दोनों को दर्शाता है। महाभारत के युद्ध के अंतिम दिन क्रोधवश अश्वथामा द्वारा अभिमन्यु के अजन्मे बच्चे
        को मारने के प्रयास के कारण श्रीकृष्ण अश्वथामा को पृथ्वी के अंत तक जीवित रहने का श्राप देते है।`,
        label: "मुख्य आकर्षण",
      },
      {
        title: "धृतराष्ट्र की पाकड़",
        image: "images/main-attractions/block-d/block-d-2.png",
        description: `यहां आप धृतराष्ट्र के दरबार की एक प्रतिकृति देख सकते है, जहां शोक संतप्त राजा अपने बेटों की मृत्यु का शोक
        मनाते हुए भीम से बदला लेना चाहता है। यह चित्रण आपको धृतराष्ट्र की भावना ओंकी तीव्रता और सटीक बदला
        लेने के उनके दृढ़ संकल्प में समाहित करता है। उनके कार्यों ने कुरु राजवंश का नाश करने वाली हिंसा और प्रतिशोध
        के चक्र को बनाए रखने में योगदान दिया। यह धृतराष्ट्र के अनसुलझे संघर्षों के गंभीर परिणामों और न्याय और
        सुलह को बनाए रखने में उनकी विफलता को दर्शाता है।`,
        label: "मुख्य आकर्षण",
      },
      {
        title: "युधिष्ठिर का राज्याभिषेक",
        image: "images/main-attractions/block-d/block-d-3.png",
        description: `इस क्षेत्र में, आप युधिष्ठिर के राज्याभिषेक के दौरान हस्तिनापुर के दरबार की भव्यता को देख सकते हैं। यहाँ उनका
        राजसी सिंहासन केंद्र में है। युधिष्ठिर का राज्याभिषेक छल और अत्याचार पर सत्य और पुण्य की जीत का संकेत है,
        जो भारतवर्ष में शांति और समृद्धि के एक नए युग का आरंभ बनता है। यह पांडवों की चुनौतीपूर्ण यात्रा की
        पराकाष्ठा और अपनी सही विरासत को पुनः प्राप्त करने में उनकी अंतिम जीत का भी प्रतीक है। युधिष्ठिर का
        राज्याभिषेक समारोह उत्सव भारतवर्ष में एकता का क्षण है, क्योंकि सहयोगी राज्य और प्रजा समान रूप से पांडव के
        नेतृत्व का सम्मान करती हैं और नए राजा के रूप में युधिष्ठिर को राजा के रूप में स्वीकार करती है।`,
        
      },
      {
        title: "स्वर्गलोक यात्रा",
        image: "images/main-attractions/block-d/block-d-4.png",
        description: `यहाँ एक सुंदर रूप से तैयार की गई लंबी संरचना आपको स्वर्ग-लोक पर चढ़ने की कल्पना करने के लिए आमंत्रित
        करती है। एक पर्दे पर प्रस्तुत, पांडव और द्रौपदी स्वर्ग-लोक की अपनी यात्रा पर मेरु पर्वत पर चढ़ते हुए मोक्ष की
        अपनी खोज शुरू करते हैं। वे कई परीक्षाओं और नैतिक दुविधाओं का सामना करते हैं, जो उनके गुण, विनम्रता और
        धर्म की समझ को चुनौती देते हैं। प्रतिकूलताओं के बावजूद, युधिष्ठिर धार्मिकता के प्रति अपनी प्रतिबद्धता में
        अडिग रहते हैं। यह यात्रा युधिष्ठिर के ईमानदार जीवन की पराकाष्ठा और धर्म को बनाए रखने के लिए उनके
        अंतिम पुरस्कार का प्रतीक है। स्वर्ग उस दिव्य क्षेत्र को दर्शाता है जहाँ धर्मी अपने न्यायपूर्ण पुरस्कार प्राप्तक रते हैं।`,
        label: "मुख्य आकर्षण",
      },
      {
        title: "गांधारी",
        image: "images/main-attractions/block-d/block-d-5.png",
        description: `महाभारता के बाद का यहाँ दृश्य आपको गांधारी के पीड़ा के क्षण में ले जाता है। द्रौपदी द्वारा श्री कृष्ण को दिया गया
        श्राप ही यदुवंशियों के पूरे कबीले के अंत का कारण बनता है। महाभारत में श्री कृष्ण पर गांधारी का श्राप एक माँ के
        गहरे दुःख और क्रोध को दर्शाता है, जिसने अपने वंश के विनाश को देखा है। न्याय और अस्तित्व की चक्रीय प्रकृति
        का प्रतीक, उनका श्राप यहाँ भाग्य की विडंबना को उजागर करता है क्योंकि वासुदेव कृष्ण जैसे बुद्धिमान और दिव्य
        भी इसके परिणामों के अधीन हैं।`,
      },
      {
        title: "द्वारका का अंत",
        image: "images/main-attractions/block-d/block-d-5.png",
        description: `यहाँ भित्ति चित्र में आप भगवान श्री कृष्ण के राज्य द्वार का को जल में पूरी तरह समा जाने के मार्मिक क्षण को देख
        सकते हैं। देवी गांधारी के दिये गये श्राप के निर्णय स्वरूप यह स्वर्ण नगरी अरब सागर की गहराई में गायब हो जाती
        है। यह घटना एक युग के समापन और द्वापर युग से कलियुग में बदलाव का प्रतीक है, जो श्रीकृष्ण के सांसारिक
        प्रयासों के अंत और एक नए युग की शुरुआत को दर्शाता है।`,
      },
      // Add other items here...
    ],
  };

  return (
    <div className="main_attractions_list">
      {content[language].map((item, index) => (
        <div className="list_item" key={index}>
          <div className="attraction_image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="attranction_details">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            {item.label && <span className="main-attraction-label">{item.label}</span>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Blockd;
