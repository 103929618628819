import React from 'react'
import { Link } from 'react-router-dom';

const VistorInfo = ({ language }) => {


    const textContent = {
        en: {
            VisitorInfoH:'Visitor Info',
            LocationH:'Location',
            Pipli:'Pipli',
            PipliKm:'(7.3KM)',
            KurukshetraJunction:'Kurukshetra Junction',
            KurukshetraJunctionKm:'(2.3 KM)',
            NewBusStand:'New Bus Stand',
            NewBusStandKm:'(3.8 KM)',
            OpeningHours:'Opening Hours',
            TuesdaySaturday:'Tuesday - Saturday',
            TuesdaySaturdayTime:'10 am. - 5.pm.',
            Sunday:'Sunday',
            SundayTime:'11 am. - 5.pm.',
            Buyentrypass:'Buy entry pass',
            GetMuseumLocation:'Get Museum Location',          
        },
        hi: {
            VisitorInfoH:'आगंतुक जानकारी',
            LocationH:'स्थान',
            Pipli:'पिपली',
            PipliKm:'(7.3 किमी) ',
            KurukshetraJunction:'कुरूक्षेत्र जंक्शन',
            KurukshetraJunctionKm:'(2.3 किमी)',
            NewBusStand:'नया बस स्टैंड',
            NewBusStandKm:'(3.8 किमी)',
            OpeningHours:'खुलने का समय',
            TuesdaySaturday:'मंगलवार - शनिवार',
            TuesdaySaturdayTime:'सुबह 10 बजे - शाम 5 बजे',
            Sunday:'रविवार',
            SundayTime:'सुबह 11 बजे - शाम 5 बजे',
            Buyentrypass:'प्रवेश पास खरीदें',
            GetMuseumLocation:'संग्रहालय का लोकेशन',
        },
      };


    const handleClick = ()=>{
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;
                const currentLocation = `${latitude},${longitude}`;
      
                // Construct the Google Maps URL with the current location
                const googleMapsUrl = `https://www.google.com/maps/dir//${currentLocation}/@30.7204658,76.7079871,12z/data=!4m9!4m8!1m1!4e2!1m5!1m1!1s0x390e39985539156b:0xf9df45df2ca4d0cb!2m2!1d76.7697114!2d29.9607997?entry=ttu`;
      
                // Redirect the user to the Google Maps URL
                // window.location.href = googleMapsUrl;
                window.open(googleMapsUrl, '_blank');
              },
              (error) => {
                console.error('Error getting current location:', error);
                // Handle error, e.g., show a message to the user
              }
            );
          } else {
            console.error('Geolocation is not supported by this browser.');
            // Handle unsupported browser, e.g., show a message to the user
          }
    }

const iframeStyle = {
    WebkitMaskImage: "url(/images/map-shap.svg)",
    maskImage: "url(/images/map-shap.svg)",
    WebkitMaskSize: '90%',
    maskSize: '90%',
    WebkitMaskRepeat: 'no-repeat',
    maskRepeat: 'no-repeat',
    WebkitMaskPosition: 'center',
    maskPosition: 'center',
  };
    return (
        <div>
            <section className="visitor-info" style={{backgroundImage:'url(/images/gradient-info-bg.svg)', maskImage:'url(/images/gradient-info-bg.svg)', WebkitMaskImage:'url(/images/gradient-info-bg.svg)'}}>
                <div className="container">
                    <div className="row m-0 align-items-center">
                        <div className="col-lg-6 p-0">
                            <div className="visitor-info-content">
                                <h2>{textContent[language].VisitorInfoH}</h2>
                                <div className="info-items">
                                    <h4><svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.33 9.97034C17.33 13.2902 13.2211 18.7883 10.8329 21.7036C9.95922 22.7702 8.37078 22.7702 7.49705 21.7036C5.10887 18.7883 1 13.2902 1 9.97034C1 5.29041 4.65559 1.49658 9.165 1.49658C13.6744 1.49658 17.33 5.29041 17.33 9.97034Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.1982 9.66165C12.1982 11.3366 10.8404 12.6944 9.16545 12.6944C7.49053 12.6944 6.13274 11.3366 6.13274 9.66165C6.13274 7.98673 7.49053 6.62893 9.16545 6.62893C10.8404 6.62893 12.1982 7.98673 12.1982 9.66165Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>{textContent[language].LocationH}</h4>
                                    <div className="info-item">
                                        <ul>
                                            <li>{textContent[language].Pipli}</li>
                                            <li>{textContent[language].KurukshetraJunction}</li>
                                            <li>{textContent[language].NewBusStand}</li>
                                        </ul>
                                        <ul>
                                            <li>{textContent[language].PipliKm}</li>
                                            <li>{textContent[language].KurukshetraJunctionKm}</li>
                                            <li>{textContent[language].NewBusStandKm}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="info-items">
                                    <h4><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 4.68421V9.94736C11 10.5287 11.4713 11 12.0526 11L17.3158 11M11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11C21 16.5228 16.5228 21 11 21Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>{textContent[language].OpeningHours}</h4>
                                    <div className="info-item">
                                        <ul>
                                            <li>{textContent[language].TuesdaySaturday}</li>
                                            <li>{textContent[language].Sunday}</li>
                                        </ul>
                                        <ul>
                                            <li>{textContent[language].TuesdaySaturdayTime}</li>
                                            <li>{textContent[language].SundayTime}</li>
                                        </ul>
                                    </div>
                                </div>
                                <Link to="/booking" style={{textDecoration: "none"}}>
                                <button className="Buy-Tickets">{textContent[language].Buyentrypass}<span><svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 6L16 6M16 6L11 1M16 6L11 11" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg></span></button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0">
                            <div style={{width: "100%"}}>
                                {/* <button onClick={handleClick}>Click</button> */}
                                <img src='/images/map-ellipse.png' alt='' onClick={handleClick} style={{cursor:"pointer"}}/>
                                {/* <iframe onC style={iframeStyle} width="100%" title='s' height="567" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=567&amp;hl=en&amp;q=Mahabharata%20Museum%20(Under%20Construction)+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=near&amp;output=embed"></iframe> */}
                                <div className='getLoation' onClick={handleClick} style={{cursor:"pointer", textAlign:"center"}}>{textContent[language].GetMuseumLocation}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default VistorInfo