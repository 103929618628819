import React, { } from 'react'
import "./style.css"
import "react-image-gallery/styles/css/image-gallery.css"
import { Link } from 'react-router-dom';
import ImageGallery from '../../components/imagegallery/ImageGallery';

const Gallery = ({ language }) => {
    const textContent = {
        en: {
            pageTitle: 'Galleries',
            MuseumGalleries: 'Museum Galleries',
            HomePage: 'Home',
        },
        hi: {
            pageTitle: 'संग्रहालय गैलरी',
            MuseumGalleries: 'संग्रहालय गैलरी',
            HomePage: 'मुख्य पृष्ठ',
        },
    };



    return (
        <div>

            <div className="inner-pages-head" style={{ backgroundImage: `url('images/about-header-bg.jpg')` }}>
                <div className="container">
                    <div className="title_and_breadcrumbs">
                        <h1>{textContent[language].pageTitle}</h1>
                        <ul>
                            <li><Link to="/">{textContent[language].HomePage}</Link></li>
                            <span></span>
                            <li>{textContent[language].pageTitle}</li>
                        </ul>
                    </div>
                    <div className="banner-logo">
                        <img src="images/mahabharat-logo.svg" alt="" />
                    </div>
                </div>
            </div>

            <section className='MuseumGalleries'>
            <div className="container">
              <h2>{textContent[language].MuseumGalleries}</h2>
            </div>
            <div className="container">
              <ImageGallery language={language}/>
            </div>
          </section>


            

        </div>
    )
}

export default Gallery
