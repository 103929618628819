import React, { useEffect, useState } from 'react'
import StarRatings from 'react-star-ratings'
import { Link, useLocation } from 'react-router-dom';
import { apiAxios } from '../../api/axios';
import { getYouTubeVideoId } from '../../components/common/youtubeThumbnailImage';
const ExhibitsDetails = () => {

    const location = useLocation();
    const [exhibitDetails, setExhibitDetails] = useState(null)
    const [video360Link, setVideo360Link] = useState(null)
    const [video360Id, setVideo360Id] = useState(null)
    const [loading, setLoading] = useState(false);

    console.log("location", location)

    const getExhibitDetails = async (id) => {
        try {
            const res = await apiAxios.get(`locations/getLocation_details?location_id=${id}`);
            setExhibitDetails(res?.data?.data[0])
            console.log("res?.data?.data[0]",res?.data?.data[0].video360_urls[0]?.media_file_name)
            setVideo360Link(res?.data?.data[0].video360_urls[0]?.media_file_name)
            const video_id = getYouTubeVideoId(res?.data?.data[0].video360_urls[0]?.media_file_name);
            setVideo360Id(video_id);
        } catch (err) {

        } finally {
            setLoading(false)
        }
    }

    console.log("video360Id",video360Id)


    useEffect(() => {
        window.scrollTo({
            top: 100,
        });
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('location_id');
        console.log('location_id:', id);
        getExhibitDetails(id)
    }, []);

    console.log("exhibitDetails", exhibitDetails)
    return (
        <div>
            <div class="detail-page-banner">
                <div class="container">
                    <div class="banner-image">
                        <img src={exhibitDetails?.web_icon} alt="" />
                    </div>
                </div>
            </div>
            <section class="mb-5">
                <div class="container">
                    <div class="details-page-data-panel">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2>{exhibitDetails?.location_name}</h2>
                                <p>{exhibitDetails?.description}</p>
                            </div>
                            <div class="col-md-6">
                            <div class="details-page-gallery">
                                <h3>Photos</h3>
                                <ul class="g-list">
                                    {
                                        exhibitDetails?.gallary_images.length > 0 ?
                                            exhibitDetails?.gallary_images.map((item) => (
                                                <li><img src={item?.media_url} alt="" /></li>
                                            ))
                                            :
                                            <p className='d-flex justify-content-center align-items-center' style={{ height: "200px" }}>No photos yet</p>

                                    }
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="details-page-gallery">
                                <h3>Video</h3>
                                {
                                    exhibitDetails?.video360_urls.length > 0 ? (
                                        <ul class="g-list">
                                            {
                                                exhibitDetails?.video360_urls.map((item) => {
                                                    // const video_id = item?.media_file_name.split("?v=")[1];
                                                    const video_id = getYouTubeVideoId(item?.media_file_name)

                                                    return (
                                                        <li>
                                                            <Link to={item.media_file_name}>
                                                                <img src={`https://img.youtube.com/vi/${video_id}/hqdefault.jpg`} alt="" /> <i><img src="images/play-video-icon.svg" alt="" /></i>
                                                            </Link>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    )
                                        :
                                        <p className='d-flex justify-content-center align-items-center' style={{ height: "200px" }}>No videos yet</p>
                                }

                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                {
                    exhibitDetails?.video360_urls.length > 0 && (
                        <div class="container">
                            <div class="three-d-view">
                                <h2>360 tour</h2>
                                <div class="three-d-iframe">
                                    <Link to={video360Link}>
                                        <img src={`https://img.youtube.com/vi/${video360Id}/hqdefault.jpg`} alt="" /> <i><img src="images/play-video-icon.svg" alt="" /></i>
                                    </Link>
                                    {/* <img src="/images/360-image.jpg" alt="" /> */}
                                    {/* <video src='https://media.w3.org/2010/05/sintel/trailer_hd.mp4' width='800' height='600' controls poster={""}></video> */}
                                </div>
                            </div>
                        </div>
                    )
                }

               
            </section>
        </div>
    )
}

export default ExhibitsDetails