import React from 'react'
import { Link } from 'react-router-dom'
import { socialLinks } from '../common/socialLinks'
import VideoContainer from '../common/aboutYoutubeVideo'

const Footer = ({ language }) => {

    const textContent = {
        en: {
            UsefulLinks:'Useful Links',        
            Booking:'Booking',        
            Exploremuseum:'Explore museum',        
            Blogs:'Blogs',        
            ContactUs:'Contact Us',        
            GiveFeedback:'Give Feedback',        
            Address:'Address',
            AddressDetail:'Mahabharat Museum, Jyotisar. Kurukshetr. (Haryana)',
            FollowUs:'Follow Us',
        },
        hi: {
            UsefulLinks:'उपयोगी लिंक',        
            Booking:'बुकिंग',        
            Exploremuseum:'संग्रहालय देखें ',        
            Blogs:'ब्लॉग',        
            ContactUs:'संपर्क करें',        
            GiveFeedback:'प्रतिक्रिया दें ',        
            Address:'पता',
            AddressDetail:'महाभारत संग्रहालय, ज्योतिसर। कुरुक्षेत्र। (हरियाणा)',
            FollowUs:'हमें फ़ॉलो करें',
        },
      };

    return (
        <div className="footer-section">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-4">
                        <div className="footer-about">
                            <Link to="#" className="footer-logo"><img src="/images/footer-logo.png" alt="" /></Link>
                            <Link to="#" className="footer-logo"><img src="/images/haryana-tourism-logo.png" alt="" /></Link>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="Useful-Links">
                            <h4>{textContent[language].UsefulLinks}</h4>
                            <ul>
                                <li><Link to="/booking">{textContent[language].Booking}</Link></li>
                                <li><Link to="/gallery">{textContent[language].Exploremuseum}</Link></li>
                                <li><Link to="/blogs">{textContent[language].Blogs}</Link></li>
                                <li><Link to="/contact-us">{textContent[language].ContactUs}</Link></li>
                                <li><Link to="/feedback">{textContent[language].GiveFeedback}</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="address">
                            <h3>{textContent[language].Address}</h3>
                            <address>
                                {textContent[language].AddressDetail}
                            </address>
                            <br />
                            <h4>{textContent[language].FollowUs}</h4>
                            <ul>
                                <li><Link to={"javascript:void(0)"} ><img src="/images/facebook.svg" alt="" /></Link></li>
                                <li><Link to={"javascript:void(0)"} ><img src="/images/youtube.svg" alt="" /></Link></li>
                                <li><Link to={"javascript:void(0)"} ><img src="/images/instagram.svg" alt="" /></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="copyright-text">
                        All rights reserved <Link to="#">Mahabharat Museum.</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer