import React from 'react';

function Blockbc({ language }) {
  // Content for both English and Hindi
  const content = {
    en: [
      {
        title: "EXILE TRIALS OF THE PANDAVAS",
        image: "images/main-attractions/block-bc/block-bc-1.png",
        description: `This elaborate display immerses you in the challenging journey of the Pandavas during their twelve-year
        vanavasa (exile to the forest) and subsequent year of agyatavasa (exile in disguise). Following their
        devastating loss of kingdom and wealth to the Kauravas in a tragic dice game, these exiles were imposed
        upon them. During this time, the Pandavas underwent profound introspection and spiritual growth,
        learning important lessons about humility, patience, and the ephemeral nature of power and wealth. These
        experiences of hardship and concealment were pivotal in shaping their eventual return to reclaim their
        kingdom and fulfill their destiny in the epic Kurukshetra war.`,
      },
      {
        title: "LORD KRISHNA’S VIRAT SWARUP",
        image: "images/main-attractions/block-bc/block-bc-2.png",
        description: `On this expensive projection screen you can encounter the Virat Swaroop of Lord Krishna. He revealed
        his Universal Form in the court of Dhritarashtra in the Shanti-Prastava Sabha in an attempt to persuade
        Duryodhana to accept peace and avoid further bloodshed. When Duryodhana beheld Krishna's Virat
        Swaroop, he was struck with fear and awe. Krishna used this moment to admonish Duryodhana for his
        arrogance and obstinacy. He urged Duryodhana to recognize his divine power and wisdom, warning him
        of the consequences of continuing his path of enmity and injustice. However, despite witnessing the
        grandeur of Krishna's Virat Swaroop and receiving his counsel, Duryodhana remained obstinate and
        refused to relent, leading to the continuation of hostilities and the eventual downfall of the Kauravas. The
        Virat Swaroop is a manifestation of Krishna's cosmic form, which encompasses the entire universe and all
        its beings. It is an awe-inspiring and overwhelming sight, meant to convey the omnipotence and
        omnipresence of the divine.`,
      },
      {
        title: "THE KURUKSHETRA WAR",
        image: "images/main-attractions/block-bc/block-bc-3.png",
        description: `Here in this most captivating installation you witness the war scenes of Kurukshetra under a spacious
        dome, chairs equipped with 360-degree rotation provides a 7-D immersive experience of the 18-day-long
        battle of the Mahabharata. This 15-minute spectacle transports you back in time, showcasing various
        episodes from the of Kurukshetra that signify the culmination of a longstanding conflict between
        righteousness and unrighteousness, as the Pandavas and Kauravas battle for justice and power. Each day
        of the war becomes a moral battleground where virtue clashes with vice, leading to the triumph of
        righteousness over treachery. Fulfilling prophecies and divine plans, the war marks the transition from
        one era to another, symbolizing the end of the Dvapara Yuga and the beginning of the Kali Yuga.`,
      },
      // Add other items here...
    ],
    hi: [
      {
        title: "पांडवों का वनवास",
        image: "images/main-attractions/block-bc/block-bc-1.png",
        description: `यह विस्तृत प्रदर्शन आपको पांडवों की बारह साल के वनवास और उसके बाद के एक वर्ष के अग्यातवास के दौरान की
        चुनौती पूर्ण यात्रा में लुप्त कर देता है। चौसर खेल में राज्य और धन के विनाशकारी नुकसान के बाद, यह वनवास
        पांडवों की चुनौती का आख़िरी पड़ाव होता है। इस दौरान, पांडवों ने विनम्रता, धैर्य और शक्ति और धनकी अस्थायी
        प्रकृति के बारे में महत्वपूर्ण सबक सीखते हुए गहन आत्म निरीक्षण और आध्यात्मिक विकास किया। कठिनाई और
        छिपाव के ये अनुभव अपने राज्य को पुनः प्राप्त करने और महाकाव्य कुरुक्षेत्र युद्ध में अपने भाग्य को पूरा करने के
        लिए उनकी अंतिम वापसी को आकार देने में महत्वपूर्ण थे।`,        
      },
      {
        title: "भगवान कृष्ण का विराट स्वरूप",
        image: "images/main-attractions/block-bc/block-bc-2.png",
        description: `यहाँ आप भगवान श्री कृष्ण के विराट स्वरूप को महसूस कर सकते हैं। धृतराष्ट्र के दरबार में दुर्योधन को शांति
        स्वीकार करने के लिए मनाने के प्रयास में श्री कृष्ण ने अपने सार्व भौमिक रूप का खुलासा किया। जब दुर्योधन ने
        कृष्ण के विराट स्वरूप को देखा, तो वह भय और विस्मय से अभिभूत हो गया। कृष्ण ने इस क्षण का उपयोग
        दुर्योधन को उसके अहंकार के लिए फटकार लगाने के लिए किया। उन्होंने दुर्योधन से उनकी दिव्यशक्ति और ज्ञान
        को पहचानने का आग्रह किया, उन्हें शत्रुता और अन्याय के अपने मार्ग को जारी रखने के परिणामों के बारे में
        चेतावनी दी। हालाँकि, कृष्ण के विराट स्वरूप की भव्यता को देखने और उनकी सलाह प्राप्त करने के बावजूद,
        दुर्योधन नहीं माने और उन्होंने हार मानने से इनकार कर दिया, जिससे शत्रुता जारी रही और अंततः कौरवों का पतन
        हुआ। विराट स्वरूप श्री कृष्ण के ब्रह्मांडीय रूपकी अभिव्यक्ति है, जिसमें पूरे ब्रह्मांड और उसके सभी प्राणी शामिल
        हैं। यह एक विस्मयकारी और अभिभूत करने वाला दृश्य है, जिसका उद्देश्य दिव्य की सर्वशक्तिमानता और
        सर्वव्यापीता को व्यक्त करना है।`,
      },
      {
        title: "कुरुक्षेत्र का युद्ध",
        image: "images/main-attractions/block-bc/block-bc-3.png",
        description: `यहाँ इस सबसे मनमोहक स्थापना में, आप एक विशाल गुंबद के नीचे कुरुक्षेत्र के युद्ध के दृश्यों को देखते हैं। यह
        360-डिग्री रोटेशन से युक्त आसन आपको महाभारत के 18-दिवसीय युद्ध का 7-डी गहन अनुभव प्रदान करती हैं।
        15 मिनट का यह वाक्य आपको समय में वापस ले जाता है। यह कुरुक्षेत्र के विभिन्न प्रसंगों को प्रदर्शित करता है जो
        धार्मिकता और अधर्म के बीच लंबे समय से चले आरहे संघर्ष की पराकाष्ठा को दर्शाता है। युद्ध का प्रत्येक दिन एक
        नैतिक युद्ध का मैदान बन जाता है जहाँ पुण्य बुराई से टकराता है, जिससे विश्वासघात पर धार्मिकता की जीत होती
        है। भविष्यवाणियों और दिव्य योजनाओं को पूरा करते हुए, यह युद्ध द्वापर युग के अंत और कलियुग की शुरुआत
        का प्रतीक है।`,
      },
      // Add other items here...
    ],
  };

  return (
    <div className="main_attractions_list">
      {content[language].map((item, index) => (
        <div className="list_item" key={index}>
          <div className="attraction_image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="attranction_details">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            {item.label && <span className="main-attraction-label">{item.label}</span>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Blockbc;
