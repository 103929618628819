import React, { useEffect, useState } from 'react';
import './style.css';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { apiAxios } from '../../api/axios';
import { Link } from 'react-router-dom';

const ContactUs = ({ language }) => {

    const textContent = {
        en: {
            pageTitle: 'Contact Us',
            HomePage: 'Home',
            REGULAREXHIBITIONHOURS: 'REGULAR EXHIBITION HOURS',
            MSTiming: 'Monday to Saturday / 10am-5pm',
            STiming: 'Sunday / 11am-5pm',
            placeholders: {
                name: 'Your Name',
                email: 'Your Email',
                subject: 'Subject',
                message: 'Your Message',
                send: 'Send',
            },
        },
        hi: {
            pageTitle: 'संपर्क करे',
            HomePage: 'मुख्य पृष्ठ',
            REGULAREXHIBITIONHOURS: 'नियमित प्रदर्शनी समय',
            MSTiming: 'सोमवार से शनिवार / सुबह 10 बजे से शाम 5 बजे तक',
            STiming: 'रविवार / सुबह 11 बजे से शाम 5 बजे तक',
            placeholders: {
                name: 'आपका नाम',
                email: 'आपका ईमेल',
                subject: 'विषय',
                message: 'आपका संदेश',
                send: 'भेजें',
            },
        },
    };

    const [formSubmitted, setFormSubmitted] = useState(false);
    const schema = yup.object().shape({
        userEmail: yup.string().email().required("Email is required"),
        userName: yup.string().required("Name is required"),
        subject: yup.string().required("Subject is required"),
        message: yup.string().required("Message is required"),
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    const onSubmitHandler = async (data) => {
        console.log({ data });
        try {
            const formData = new FormData();
            formData.append("userName", data.userName);
            formData.append("userEmail", data.userEmail);
            formData.append("subject", data.subject);
            formData.append("message", data.message);

            const response = await apiAxios.post(
                "contact_us/add_contact",
                formData
            );
            setFormSubmitted(true);
            console.log(response.data); // Assuming the response contains JSON data
        } catch (error) {
            console.error(error);
        }
        reset();
    };

    return (
        <div>
            <div className="inner-pages-head" style={{ backgroundImage: `url('images/about-header-bg.jpg')` }}>
                <div className="container">
                    <div className="title_and_breadcrumbs">
                        <h1>{textContent[language].pageTitle}</h1>
                        <ul>
                            <li><Link to="/">{textContent[language].HomePage}</Link></li>
                            <span></span>
                            <li>{textContent[language].pageTitle}</li>
                        </ul>
                    </div>
                    <div className="banner-logo">
                        <img src="images/mahabharat-logo.svg" alt="" />
                    </div>
                </div>
            </div>

            <section className="mt-5 mb-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <div className="rath-img">
                                <img src="/images/contact-img.png" alt="" />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="feedback-form contact-us">
                                <h4>{textContent[language].REGULAREXHIBITIONHOURS}</h4>
                                <h5>{textContent[language].MSTiming} <br />{textContent[language].STiming}</h5>
                                <form onSubmit={handleSubmit(onSubmitHandler)}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-field">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={textContent[language].placeholders.name}
                                                    {...register("userName")}
                                                />
                                                <span className="form-error-msg">{errors?.userName?.message}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-field">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder={textContent[language].placeholders.email}
                                                    {...register("userEmail")}
                                                />
                                                <span className="form-error-msg">{errors?.userEmail?.message}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-field">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={textContent[language].placeholders.subject}
                                            {...register("subject")}
                                        />
                                        <span className="form-error-msg">{errors?.subject?.message}</span>
                                    </div>
                                    <div className="input-field">
                                        <textarea
                                            className="form-control"
                                            placeholder={textContent[language].placeholders.message}
                                            {...register("message")}
                                        ></textarea>
                                        <span className="form-error-msg">{errors?.message?.message}</span>
                                    </div>
                                    <div className="btn-grp">
                                        <button type="submit" className="submit-btn">
                                            {textContent[language].placeholders.send}
                                        </button>
                                    </div>
                                </form>
                                {formSubmitted && (
                                    <div className="alert alert-success alert-dismissible fade show mt-3" role="alert">
                                        <strong>Form Submitted Successfully!</strong>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ContactUs;
