import React from 'react';

function Blocka({ language }) {
  // Content for both English and Hindi
  const content = {
    en: [
      {
        title: "SHLOKA",
        image: "images/main-attractions/block-b/block-b-1.png",
        description: `The verse dedicated to Draupadi is placed here on the wall that beautifully describes her on the moment
        when she was born out of the fire “The virgin maiden, with bow like arms, the princess of Panchaal... She
        emerged from the middle of the sacrificial fire..She is the bringer of good fortune, the most beautiful to
        behold..”. In the Mahabharata, Draupadi's beauty at the time of her birth is described as ethereal and
        divine. Her beauty was not merely physical but also spiritual, reflecting her divine origins and purity.
        Draupadi's luminous presence was said to illuminate the surroundings, and her aura was so captivating
        that it left those around her in awe.`,
      },
      {
        title: "DRAUPADI BIRTH",
        image: "images/main-attractions/block-b/block-b-2.png",
        description: `Here in this installation, the pivotal moment of Draupadi's birth, emerging from the fire, is brought to life
      through a hyper-realistic sculpture, meticulously crafted by artisans from Kolkata. Draupadi's emergence
      from the fire symbolizes her purity and divine origin. She is often depicted as radiant and ethereal,
      embodying qualities of grace, strength, and resilience. Her birth from the fire also signifies her association
      with Agni, the purifier, and her role in purifying the Kuru dynasty through her actions. Here to create a
      realistic picture of this scene the artist from Shantiniketan, West Bengal meticulously designed the base of
      this installation. Draped in a specially crafted red saree from Banaras, Draupadi radiates unparalleled
      grace and fortitude highlighting her divine origins, purity, and significance in the epic narrative.`,
      label: "Main Attraction",
      },
      {
        title: "BIRTH OF DHRISHTADYUMNA & SHIKHANDI",
        image: "images/main-attractions/block-b/block-b-3.png",
        description: `This panel here showcases the breathtakingly large mural crafted by artisans from Kolkata, depicting
        Rishi Dronacharya, the royal preceptor of the Kuru princes and the Panchala king, Drupada. This
        portrayal explores their childhood friendship evolving into rivalry, culminating in Rishi Drauna's order for
        the Pandavas to capture King Drupada. In response, King Drupada, fueled by vengeance, orchestrates a
        penance, resulting in granting of a boy- Dhrishtadyumna and a girl- Draupadi by the Gods. To get a more
        immersive experience you can delve deep into the narrative projected on the screen.
        Another mural here presents a spectacular sight, depicting Drupada's eldest offspring, Shikhandi, made by
        artists from Kolkata, engaged in battle, depicting valor as she participated in the great Mahabharata war
        from the Pandavas' side. Shikhandi's presence on the battlefield was instrumental in the death of Bhishma,
        as Bhishma had taken a vow not to fight against someone he perceived as a female. Shikhandi was born
        as a female, but due to a boon from a Yaksha, she was later transformed into a male. Shikhandi's inclusion
        in the battle allowed Arjuna to attack Bhishma without reservation, ultimately leading to Bhishma's
        downfall.`,
      },
      {
        title: "KHANDAV PRAST",
        image: "images/main-attractions/block-b/block-b-4.png",
        description: `The tale unfolds with Lord Agni assuming the guise of a Brahmana, imploring Arjuna and Krishna for
        their aid in burning the Khandava forest to quell his hunger. Home to various tribes, including the Nagas,
        the forest is burnt, sparing only a snake named Ashwasena, who was the son of Takshaka, the king of the
        snakes or Nagaraja. Takshaka's wife sacrifices herself to protect Ashwasena. In retaliation for her demise,
        Takshaka slew Parikshit, who had also been cursed by the sage Shringi to meet his end through a snake's
        bite. Prakshit was the son of Abhimanyu and Uttara, and the grandson of Arjuna. Here 2 hyper realistic
        sculptures are put together by the Kolkata artists to narrate this entire scene. One is of Arjuna who is
        aiming at the Khandava forest with his bow and the other one is of Parikhit meditating on lord Vishnu
        killed by the bite of Takshaka. Adding further depth to the narrative, a projection screen showcases the
        momentous event of the Khandava forest's fiery demise.`,
        label: "Main Attraction",
      },
      {
        title: "DRAUPADI SWAYAMVAR",
        image: "images/main-attractions/block-b/block-b-5.png",
        description: `This exhibit offers an immersive experience of Draupadi's Swayamvar, portrayed through a hyper-realistic
        sculpture of Arjuna, poised with his bow, taking aim at the fish's eye. Crafted meticulously by artists from
        Kolkata, this sculpture vividly captures the grandeur of the event. King Drupada devised a rigorous
        challenge to assess the suitors' strength and skill during the Swayamvar. The task involved shooting an
        arrow through the eye of a revolving fish suspended over a water tank, with only its reflection for
        guidance. This demanding feat demanded exceptional precision and ability. When Arjuna undertook the
        challenge, he displayed remarkable prowess by successfully hitting the fish's eye. Consequently, as per the
        swayamvara's conditions, Draupadi was wedded to Arjuna. Here the entire installation, skillfully crafted
        by artists from Shantiniketan, West Bengal, offers you the opportunity to engage in the aiming game. A
        stage is provided here, allowing you to take aim at the eye by pressing a button positioned in front of you.
        Additionally, you can observe other Pandava brothers, disguised as Brahmins, amidst the crowd, adding
        further depth to the immersive experience.`,
      },
      {
        title: "LAKSHAGRAHA",
        image: "images/main-attractions/block-b/block-b-6.png",
        description: `Here you witness the gripping portrayal of the Lakshagraha inferno on a screen, where Duryodhana,
        alongside his maternal uncle Shakuni, plotted the demise of the Pandavas and their mother within the
        "House of Lac," a structure designed by the Kauravas to be easily ignited. However, the Pandavas, aided
        by Vidura, their sagacious uncle, and Krishna, their loyal friend and charioteer, foiled the scheme of
        Kauravas by tunneling their way to safety. To enhance your experience a mural depicting the Pandavas
        and Kunti fleeing Lakshagraha is placed here created specially by artists from Delhi. This failed
        assassination attempt hints at the impending conflict between the Pandavas and Kauravas, foreshadowing
        the epic Kurukshetra war, which will shape the destiny of the Kuru dynasty.`,
        label: "Main Attraction",
      },
      {
        title: "CHAUSAR GAME",
        image: "images/main-attractions/block-b/block-b-7.png",
        description: `Here you find yourself in a pragmatic court that replicates the setup of the Hastinapur royal assembly on
        the day of the dice game with each move carrying the weight of the kingdom's future. A colossal dice
        illustration dominates the space, hanging from the ceiling, its intricately carved faces reflecting the
        gravity of the game being played below. Amidst the grandeur of the court, the tension is palpable,
        mirroring the atmosphere of that historic day. The colossal dice above seems to cast a shadow over the
        proceedings, its presence a constant reminder of the game's ruthless unpredictability. In the Mahabharata
        with this moment, the Hastinapur court becomes a stage for the timeless struggle between honor and
        ambition, righteousness and deceit. The clash of personalities and principles reverberates through the
        chamber, leaving an indelible mark on all who bear witness to it.`,
      },
      {
        title: "DRAUPADI SAMWAD",
        image: "images/main-attractions/block-b/block-b-8.png",
        description: `Draupadi's dialogue after the dice game holds profound symbolism within the context of the Mahabharata
        epic. Her words carry the weight of defiance against the unjust social order that allowed her to be
        gambled away like a mere object. By questioning the legitimacy of the game and challenging the moral
        authority of the Kuru elders, Draupadi embodies the voice of resistance against oppression and injustice.
        Here on the screen you get cajoled by Draupadi's poignant dialogue unfolding, laying the groundwork for
        the Kurukshetra war put together beautifully with a projection.`,
      },
      // Add other items here...
    ],
    hi: [
      {
        title: "श्लोक",
        image: "images/main-attractions/block-b/block-b-1.png",
        description: `द्रौपदी को समर्पित कविता यहाँ दीवार पर रखी गई है जो उस क्षण का खूबसूरती से वर्णन करती है जब वह आग से
        पैदा हुई थी "कुंआरी लड़की, धनुष जैसी बाहों के साथ, पंचाल की राजकुमारी... वह बलिदान की आग के बीच से
        उभरी। वह सौभाग्य लाने वाली है, देखने में सबसे सुंदर है...
        महाभारत में द्रौपदी के जन्म के समय की सुंदरता को अलौकिक और दिव्य बताया गया है। उनकी सुंदरता केवल
        शारीरिक ही नहीं बल्कि आध्यात्मिक भी थी, जो उनकी दिव्य उत्पत्ति और शुद्धता को दर्शाती थी। कहा जाता था कि
        द्रौपदी की चमकदार उपस्थिति आसपास के वातावरण को रोशन करती थी, और उनकी आभा इतनी मनमोहक थी
        कि इसने उनके आसपास के लोगों को चकित कर दिया।`,        
      },
      {
        title: "द्रौपदी जन्म",
        image: "images/main-attractions/block-b/block-b-2.png",
        description: `यहाँ इस स्थापना में, द्रौपदी के जन्म के महत्वपूर्ण क्षण, आग से उभरते हुए, एक अति-यथार्थवादी मूर्तिकला के
        माध्यम से जीवन में लाया गया है, जिसे कोलकाता के कारीगरों द्वारा सावधानीपूर्वक तैयार किया गया है। द्रौपदी
        का आग से उभरना उसकी शुद्धता और दिव्य उत्पत्ति का प्रतीक है। उन्हें अक्सर उज्ज्वल और अलौकिक के रूप में
        चित्रित किया जाता है, जो अनुग्रह, और शक्ति के गुणों का प्रतीक है। अग्नि से उनका जन्म भी शुद्ध करने वाली
        अग्नि के साथ उनके जुड़ाव और अपने कार्यों के माध्यम से कुरु राजवंश को शुद्ध करने में उनकी भूमिका को दर्शाता
        है। यहाँ इस दृश्य की एक यथार्थवादी तस्वीर बनाने के लिए पश्चिम बंगाल के शांतिनिकेतन के कलाकार ने इस
        स्थापना के आधार को सावधानीपूर्वक बनाया है। बनारस से विशेष रूप से तैयार की गई लालसाड़ी में लिपटे द्रौपदी
        की मूर्ति को महाकाव्य कथा में अपनी दिव्य उत्पत्ति, शुद्धता और महत्वको उजागर करते हुए अद्वितीयकृपा और
        धैर्य का प्रदर्शन करने वाली दिखाया गया हैं।`,
        label: "मुख्य आकर्षण",
      },
      {
        title: "धृष्टद्युम्न और शिखांडी का जन्म",
        image: "images/main-attractions/block-b/block-b-3.png",
        description: `यहाँ इस पैनल में कोलकाता के कारीगरों द्वारा बनाए गए लुभावने रूप से भित्ति चित्र बनाये गए हैं, जिसमें कुरु
        राजकुमारों के शाही गुरु ऋषि द्रोणाचार्य और पंचाल राजा द्रुपद को दर्शाया गया है। यह चित्रण उनकी बचपन की
        दोस्ती के प्रतिद्वंद्विता में विकसित होने को दिखाता है। जब राजा द्रुपद, प्रतिशोध से प्रेरित होकर, एक तपस्या का
        आयोजन करते है, जिसके परिणामस्वरूप देवताओं द्वारा एक लड़का-धृष्टद्युम्न और एक लड़की-द्रौपदी प्रदान की
        जाती है। एक अधिक तल्लीन करने वाला अनुभव प्राप्त करने के लिए आप यहाँ पर प्रस्तुत कथा में गहराई से उतर
        सकते हैं। यहाँ एक अन्य भित्ति चित्र एक शानदार दृश्य प्रस्तुत करता है, जिसमें द्रुपद की सबसे बड़ी संतान, शिखंडी को चित्रित
        किया गया है, जिसे कोलकाता के कलाकारों द्वारा बनाया गया था, जो युद्ध मेंल गी हुई थी, जिसमें पांडवों की ओर
        से महान महाभारत युद्ध में भाग लेने के दौरान वीरता का चित्रण किया गया था। युद्ध के मैदान में शिखंडी की
        उपस्थिति ने भीष्म की मृत्यु में महत्वपूर्ण भूमिका निभाई है। शिखंडी का जन्म एक महिला के रूप में हुआ था,
        लेकिन एक यक्ष के वरदान के कारण, वह बाद में एक पुरुष में बदल गई। युद्ध में शिखंडी के शामिल होने से अर्जुन
        को बिना किसी आपत्ति के भीष्म पर हमला करने का मौका मिला, जिस से अंततः भीष्मका पतन हो गया।`,
      },
      {
        title: "खांडव-प्रस्थ",
        image: "images/main-attractions/block-b/block-b-4.png",
        description: `यह कहानी भगवान अग्नि के ब्राह्मण का भेष धारण करने के साथ शुरू होती है, जब अग्नि देव अर्जुन और कृष्ण से
        उनकी भूख को शांत करने के लिए खाण्डव जंगल को जलाने में उनकी सहायता के लिए विनती करते है। नागाओं
        सहित विभिन्न जनजातियों का घर, जंगल को जला दिया जाता है, केवल अश्वसेन नाम के एक सांप को छोड़ दिया
        जाता है, जो सांपों के राजा- नागराज, तक्षक का पुत्र था। अश्व से नकी रक्षा के लिए तक्षक की पत्नी अपना बलिदान
        देती है। उसकी मृत्यु के प्रतिशोध में, तक्षक ने परीक्षित को मार डाला, जिसे ऋषि श्रृंगेरी ने भी सांप के काटने से
        अपने अंत का सामना करने के लिए शाप दिया था। परीक्षित, अभिमन्यु और उत्तरा के पुत्र और अर्जुन के पोते थे।
        यहाँ इस पूरे दृश्य का वर्णन करने के लिए कोलकाता के कलाकारों द्वारा 2 अति यथार्थवादी मूर्तियों को एक साथ
        रखा गया है। एक अर्जुन का है जो अपने धनुष से खांडव वन को निशाना बना रहा है और दूसरा परीक्षित का है जो
        तक्षक के काटने से मारे जाने से पहले भगवान विष्णु का ध्यान कर रहे है।`,
        label: "मुख्य आकर्षण",
      },
      {
        title: "द्रौपदी स्वयंवर",
        image: "images/main-attractions/block-b/block-b-5.png",
        description: `यह प्रदर्शनी द्रौपदी के स्वयंवर का एक अनुभव प्रदान करती है, जिसे अर्जुन की एक अति-यथार्थवादी मूर्ति के
        तमाध्यम से चित्रि किया गया है, जो मछली की आंख के लिए अपने धनुष के साथ तैयार है। कोलकाता केकलाकारों
        द्वारा सावधानीपूर्वक तैयार की गई यह मूर्ति इस आयोजन की भव्यता को स्पष्ट रूप से दर्शाती है। राजा द्रुपद ने
        स्वयंवर के दौरान दावेदारों की ताकत और कौशल का आकलन करने के लिए एक कठोर चुनौती तैयार की। इस कार्य
        में एक पानी की टंकी के ऊपर लटकती एक घूमने वाली मछली की आंख के माध्यम से एकतीर मारना शामिल था,
        जिसमें मार्गदर्शन के लिए केवल उसका प्रतिबिंब था। इस चुनौतीपूर्ण उपलब्धि के लिए असाधारण सटीकता और
        क्षमता की आवश्यकता थी। जब अर्जुन ने चुनौती स्वीकार की, तो उन्होंने मछली की आंखपर सफलतापूर्वक प्रहार
        करके उल्लेखनीय कौशल का प्रदर्शन किया। परिणाम स्वरूप, स्वयंवर की शर्तों केअनुसार, द्रौपदी का विवाह अर्जुन
        से हुआ। यहाँ की पूरी स्थापना, पश्चिम बंगाल के शांति निकेतन के कलाकारों द्वारा कुशलता से तैयार की गई है, जो
        आपको इस खेल में शामिल होने का अवसर प्रदान करती है। यहाँ एक मंच प्रदान किया गया है, जिससे आप अपने
        सामने स्थित एक बटन दबाकर आंख के लिए लक्ष्य बना सकते हैं। इसके अतिरिक्त, आप भीड़ के बीच ब्राह्मणों के
        भेष में अन्य पांडव भाइयों को देख सकते हैं, जो इस अनुभव में और गहराई जोड़ते हैं।`,
      },
      {
        title: "लाक्षागृह",
        image: "images/main-attractions/block-b/block-b-6.png",
        description: `यहाँ आप एक पर्दे पर लाक्षागृह का मनोरंजक चित्रण देखते हैं, जहाँ दुर्योधन ने अपने मामा शकुनी के साथ मिलकर
        पांडवों और उनकी माँ कुन्ती की मृत्यु की साजिश "लाक के घर" के भीतर रची थी, जिसे कौरवों द्वारा आसानी से
        प्रज्वलित करने के लिए बनाया गया था। हालाँकि, पांडवों ने अपने दूरदर्शी चाचा विदुर और उनके मित्र और सारथी
        श्री कृष्ण की सहायता से पांडवों की सुरक्षा के लिए सुरंग बनाकर कौरवों की योजना को विफल कर दिया था। आपके
        अनुभव को बढ़ाने के लिए लाक्षागृह से भाग रहे पांडवों और कुंती को दर्शाने वाला एक भित्ति चित्र यहां रखा गया है
        जिसे विशेष रूप से दिल्ली के कलाकारों द्वारा बनाया गया है। हत्या का यह असफल प्रयास पांडवों और कौरवों के
        बीच आसन्न संघर्ष का संकेत देता है, जो महाकाव्य कुरुक्षेत्र युद्ध का पूर्वाभास देता है। यह युद्ध ही आगे चलकर
        कुरु राजवंश के भाग्य को आकार देगा।`,
        label: "मुख्य आकर्षण",
      },
      {
        title: "चौसर खेल",
        image: "images/main-attractions/block-b/block-b-7.png",
        description: `यहाँ आप खुद को एक व्यावहारिक दरबार में पाते हैं जो चौसर खेल के दिन हस्तिनापुर शाही सभा के आंतरिक
        व्यवस्थता को दोहराता है। यहाँ खेली गई प्रत्येक चाल कुरु राज्य के भविष्य का भार वहन करती है। छत से लटकते
        हुए एक विशाल पासा अपनी जटिल नक्काशी और अपने मौलिक रूप से दरबार में इसके नीचे खेले जा रहे खेल की
        गंभीरता को दर्शाता हैं। इसकी उपस्थिति खेल की क्रूर अप्रत्याशितता की निरंतर याद दिलाती है। महाभारत में इस
        क्षण के साथ हस्तिनापुर दरबार सम्मान और महत्वाकांक्षा, धार्मिकता और छल के बीच कालातीत संघर्ष का एक
        मंच बन जाता है। व्यक्तित्वों और सिद्धांतों का टकराव पूरे कक्ष में गूंजता है, जो इसकी गवाही देने वाले सभी लोगों
        पर एक अमिट छाप छोड़ता है।`,
      },
      {
        title: "द्रौपदी संवाद",
        image: "images/main-attractions/block-b/block-b-8.png",
        description: `चौसर खेल के बाद द्रौपदी का संवाद महाभारत महाकाव्य के संदर्भ में गहरा प्रतीकवाद रखता है। उसके शब्द उस
        अन्यायपूर्ण सामाजिक व्यवस्था के खिलाफ अवज्ञा का भार उठाते हैं जिसने उसे केवल एक वस्तु की तरह जुआ में
        दांव पे लगाने की अनुमति दी। खेल की वैधता पर सवाल उठाकर और कुरु बुजुर्गों के समक्ष अपने नैतिक अधिकार
        की अवहेलना की बात कहकर, द्रौपदी उत्पीड़न और अन्याय के खिलाफ प्रतिरोधकी आवाज का प्रतीक है। यहाँ पर्दे
        पर, आप द्रौपदी के मर्मस्पर्शी संवाद से मंत्रमुग्ध हो जाते हैं, जो एक प्रक्षेपण के साथ कुरुक्षेत्र युद्ध की नींव को
        खूबसूरती से एक साथ रखता है।`,
      },
      // Add other items here...
    ],
  };

  return (
    <div className="main_attractions_list">
      {content[language].map((item, index) => (
        <div className="list_item" key={index}>
          <div className="attraction_image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="attranction_details">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            {item.label && <span className="main-attraction-label">{item.label}</span>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Blocka;
