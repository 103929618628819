import React from 'react';

function Blockf({ language }) {
  // Content for both English and Hindi
  const content = {
    en: [
      {
        title: "Abhigyan Shakuntalam",
        image: "images/main-attractions/block-f/block-f-1.png",
        description: `The story of Shakuntala will come alive highlighting the virtues of patience, forgiveness, and the resilience of the human spirit. Discover how India came to be known as "Bharatavarsha” named after Bharata- a brave and powerful ruler. `,
      },
      {
        title: "Mahabharat ka Mahanayak",
        image: "images/main-attractions/block-f/block-f-2.png",
        description: `The life of Lord Krishna, his Avatars and his pivotal presence in the Mahabharata will enthral you. Discover the various roles he played from being a guide, protector, motivator, strategist while steering the course of events and upholding the cosmic order.`,
      },
      // Add other items here...
    ],
    hi: [
      {
        title: "अभिज्ञान शाकुंतलम",
        image: "images/main-attractions/block-f/block-f-1.png",
        description: `शकुंतला की कहानी धैर्य, क्षमा और मानवीय भावना के लचीलेपन के गुणों को उजागर करते हुए जीवंत हो जाएगी। जानें कि कैसे भारत को "भारतवर्ष" के नाम से जाना जाने लगा, जिसका नाम भरत के नाम पर रखा गया - एक बहादुर और शक्तिशाली शासक। `,        
      },
      {
        title: "महाभारत के महानायक",
        image: "images/main-attractions/block-f/block-f-2.png",
        description: `भगवान कृष्ण का जीवन, उनके अवतार और महाभारत में उनकी महत्वपूर्ण उपस्थिति आपको रोमांचित कर देगी। घटनाओं के क्रम को नियंत्रित करते हुए मार्गदर्शक, रक्षक, प्रेरक, रणनीतिकार से लेकर ब्रह्मांडीय व्यवस्था को बनाए रखने तक उन्होंने जो विभिन्न भूमिकाएँ निभाईं, उन्हें जानें। `,
      },
      // Add other items here...
    ],
  };

  return (
    <div className="main_attractions_list">
      {content[language].map((item, index) => (
        <div className="list_item" key={index}>
          <div className="attraction_image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="attranction_details">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            {item.label && <span className="main-attraction-label">{item.label}</span>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Blockf;
