import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import BlogsPage from './pages/Blogs';
import Navbar from './components/Header/navbar';
import Footer from './components/footer';
import BookingPage from './pages/Booking';
import Exhibits from './pages/Exhibits';
import BlogDetails from './pages/Blogs/blogDetails';
import ContactUs from './pages/contactUs';
import ExhibitsDetails from './pages/Exhibits/exhibits-details';
import Gallery from './pages/gallery';
import Feedback from './pages/feedback';
import ProceedTopay from './pages/proceedToPay';
import TicketDetails from './pages/Booking/ticketDetails';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import AboutMusuem from './components/Home/aboutMuseum';

function App() {

  const queryClient = new QueryClient()

  const [language, setLanguage] = useState('en'); // Default language is English

    // Language change handler
    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
    };




  return (
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Navbar language={language} onLanguageChange={handleLanguageChange}/>
          <Routes>
            <Route path="/" element={<Home language={language} />} />
            <Route path="/home" element={<Home language={language} />} />
            <Route path="/booking" element={<BookingPage language={language} />} />
            <Route path="/about" element={<AboutMusuem language={language} />} />
            <Route path="/ticket-details" element={<TicketDetails language={language}/>} />
            <Route path="/blogs" element={<BlogsPage />} />
            <Route path="/blogs/blog_listing" element={<BlogDetails />} />
            <Route path="/main-attractions" element={<Exhibits language={language} />} />
            <Route path="/exhibits-details" element={<ExhibitsDetails />} />
            <Route path="/blogDetails" element={<BlogDetails />} />
            <Route path="/contact-us" element={<ContactUs language={language}/>} />
            <Route path="/gallery" element={<Gallery language={language} />} />
            <Route path="/feedback" element={<Feedback language={language} />} />
            <Route path="/procced-to-pay" element={<ProceedTopay />} />
            <Route path="/*" element={<Home language={language} />} />
          </Routes>
          <Footer language={language}/>
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
}

export default App;




