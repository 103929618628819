import React from 'react';

function Blockc({ language }) {
  // Content for both English and Hindi
  const content = {
    en: [
      {
        title: "GANDHIV INSTALLATION",
        image: "images/main-attractions/block-c/block-c-1.png",
        description: `This captivating panel showcases depictions of Sri Krishna, Arjuna, and his famed Gandiva bow, evoking
        profound symbolism and the formidable prowess of a warrior and his armaments. Gandiva is a divine
        bow bestowed upon Arjuna by Agni, the deity associated with fire. Arjuna's skill with his formidable
        weapon is renowned, symbolizing his exceptional skill as a warrior. His ability to wield the bow with
        precision and effectiveness makes him one of the greatest archers in Hindu mythology. Gandiva is a
        symbol of righteousness and dharma. Guided by Sri Krishna, Arjuna's actions on the battlefield reflect a
        commitment to ethical conduct and adherence to dharma, enhancing the symbolic significance of
        Gandiva.
        (EXTRA: Additionally, the presence of the Swastika symbol atop the central roof further enriches its
        spiritual ambiance, representing the sun and the cyclical nature of creation, preservation, and destruction
        in Hindu cosmology.)`,
      },
      {
        title: "9 HIGHLIGHTS OF WAR",
        image: "images/main-attractions/block-c/block-c-2.png",
        description: `This segment introduces the lesser-known narratives of the Mahabharata, which, though occurring in the
        background, significantly impacted the overall story. Nine large screens positioned here act as gateways to
        delve into these tales, collected through scanners that can be linked to your smartphones, offering a
        comprehensive audio-visual experience. Among these accounts is the heroic saga of Barbaric, also known
        as Khtu Shyam Ji, whose contributions were crucial to the epic's unfolding. Another story features Udupi
        Naresh, who played a role in the war by cooking for both the Pandavas and Kauravas, and possessed the
        foresight to predict the next day's casualties with the help of Lord Krishna. Additionally, this section
        offers glimpses into lesser-explored narratives, such as the destruction of Arjuna's chariot, the
        employment of the Narayanastra by Ashwatthama, and the tale of Sarpabana involving the snake
        Ashwasena seeking vengeance for his family's demise. Furthermore, it presents poignant moments,
        including the demise of Bhima's son Ghatotkacha and the epic battle between Bakasura and Bhima.`,
        label: "Main Attraction",
      },
      {
        title: "BHEESHMA DEATH",
        image: "images/main-attractions/block-c/block-c-3.png",
        description: `The depiction of the pivotal episode of the great Mahabharata war: the Bhishma-shayya showcases his
        choice to remain alive on a bed of arrows despite being able to end his life earlier symbolizes his
        commitment to his duty and his sacrifice for the greater good.. Here, state-of-the-art techniques are
        employed to create an immersive experience for you, including a hyper-realistic sculpture of Bhishma,
        crafted by artists from Kolkata, pierced with multiple arrows emerging from within the stage area. To
        heighten the illusion, hundreds of specially crafted arrows with light effects have been put together and
        are suspended from the ceiling.
        (EXTRA: Accompanying this poignant installation is a backdrop poster, enriching the narrative with the
        Pandavas, particularly Yudhishthira, seeking counsel on righteous kingship from Bhishma. Bhishma's
        teachings to the Pandavas carry themes of redemption and forgiveness. Despite his role in the
        Kurukshetra war and the suffering it caused, Bhishma's willingness to impart wisdom to the Pandavas
        reflects a sense of atonement and a desire for reconciliation.)`,
        label: "Main Attraction",
      },
      {
        title: "DATING OF MAHABHARATA",
        image: "images/main-attractions/block-c/block-c-4.png",
        description: `This segment provides concrete evidence that supports the historical accuracy of events described in the
        Mahabharata. It does so by pinpointing astronomical references, especially those linked to celestial events
        like eclipses, planetary alignments, and star patterns. These references are then analyzed using modern
        astronomical tools to estimate the time frames of these events. Visual representations of constellations
        during the era of the Mahabharata, validated by contemporary astronomical methods, are displayed here
        on large circular screens. You can immerse yourself in a planetarium-like experience by sitting here and
        taking it all in.`,
      },
      {
        title: "ANCIENT ARTIFACTS",
        image: "images/main-attractions/block-c/block-c-5.png",
        description: `Here you can experience the art of ancient stone carving techniques in the form of sculptures from the
        Gurjara-Pratihara period, dating back to the 8th and 9th centuries CE, excavated at Pehowa, Kurukshetra,
        in present-day Haryana. Among these exquisite artifacts are scenes from the Mahabharata, such as the
        Putna Vadh illustrating the confrontation between the demoness Putna and Lord Krishna, Arjuna in a
        fierce fighting pose, and the iconic Jarasandha Vadh, showcasing the defeat of the powerful king of
        Magadha. Additionally, the gallery features several artifacts depicting warriors on their chariots in
        dynamic fighting postures, offering glimpses of the legendary Kurukshetra war. Alongside these battle
        scenes, you can marvel at sculptures related to Hindu deities, including Shiva sitting with Nandi holding
        an urn, the Kosala brothers Ram and Laxman meeting Parshurama, and Hanumana holding a mountain in
        his hands. These intricately crafted sculptures are elegantly displayed on marble pedestals, enhancing the
        aesthetic allure of the gallery and providing a captivating insight into ancient Indian art and mythology.`,
        label: "Main Attraction",
      },
      // Add other items here...
    ],
    hi: [
      {
        title: "गांडीव",
        image: "images/main-attractions/block-c/block-c-1.png",
        description: `यह मनोरम प्रदर्शिनी श्री कृष्ण, अर्जुन और उनके प्रसिद्ध गांडीव धनुष के चित्रण को प्रदर्शित करता है, जो एक
        योद्धा और उसके हथियारों के गहन प्रतीकवाद और दुर्जेय कौशल को उजागर करता है। गांडीव अग्नि देव द्वारा
        अर्जुन को दिया गया एक दिव्य धनुष है। अपने दुर्जेय हथियार के साथ अर्जुन का कौशल प्रसिद्ध है, जो एक योद्धा
        के रूप में उनके असाधारण कौशल का प्रतीक है। सटीकता और प्रभावशीलता के साथ धनुष को चलाने की उनकी
        क्षमता उन्हें हिंदू पौराणिक कथाओं में सबसे महान तीरंदाजों मेंसे एक बनाती है। श्री कृष्ण द्वारा निर्देशित, युद्धके
        मैदान में अर्जुन के कार्य नैतिक आचरण और धर्म के पालन के प्रति प्रतिबद्धता को दर्शाते हैं, जिससे गांडीव का
        प्रतीकात्मक महत्व बढ़ जाता है।`,        
      },
      {
        title: "युद्ध की मुख्य 9 कहानियां",
        image: "images/main-attractions/block-c/block-c-2.png",
        description: `यह खंड महाभारत के कम ज्ञात आख्यानों का परिचय देता है, जो पृष्ठभूमि में होने के बावजूद, समग्र कहानी को
        महत्वपूर्ण रूप से प्रभावित करता है। यहाँ स्थित नौ बड़े प्रदर्शिनी इन कहानियों में तल्लीन होने के लिए प्रवेश द्वार
        के रूप में कार्य करते हैं, जो एक व्यापक अनुभव प्रदान करता है। इन विवरणों में बर्बर की वीर गाथा है, जिसे खाटू
        श्याम जी के नाम से भी जाना जाता है, जिनका योगदान महाकाव्य के सामने आने में महत्वपूर्ण था। एक अन्य
        कहानी में उडुपी नरेश को दिखाया गया है, जिसने पांडवों और कौरवों दोनों के लिए खाना बनाकर युद्ध में भूमिका
        निभाई और भगवान श्री कृष्ण की मदद से अगले दिन के हताहतों की भविष्यवाणी करने की दूरदर्शिता थी। इसके
        अतिरिक्त, यह खंड कम खोजे गए आख्यानों की झलक प्रस्तुत करता है, जैसे कि अर्जुन के रथ का विनाश,
        अश्वत्थामा द्वारा नारायणशास्त्र का प्रयोग, और सर्पबान की कहानी जिसमें सांप अश्वसेन अपने परिवार की मृत्यु
        का बदला लेना चाहता है। इसके अलावा, यह भीम के पुत्र घटोत्कच की मृत्यु और बकासुर और भीम के बीच
        महाकाव्य युद्ध सहित मार्मिक क्षणों को प्रस्तुत करता है।`,
        label: "मुख्य आकर्षण",
      },
      {
        title: "भीष्म-शय्या",
        image: "images/main-attractions/block-c/block-c-3.png",
        description: `महान महाभारत युद्ध के महत्वपूर्ण प्रकरण का चित्रणः भीष्म-शय्या अपने जीवन को समाप्त करने में सक्षम होने
        के बावजूद तीरों के बिस्तर पर जीवित रहने की अपनी पसंद को दर्शाता है, जो पहले अपने कर्तव्य के प्रति उनकी
        प्रतिबद्धता और अधिक अच्छे के लिए उनके बलिदान का प्रतीक है। यहाँ, आपके लिए एक गहन अनुभव बनाने के
        लिए अत्याधुनिक तकनीकों का उपयोग किया गया है, जिसमें भीष्म की एक अति-यथार्थवादी मूर्ति शामिल है, जिसे
        कोलकाता के कलाकारों द्वारा तैयार किया गया है, जिसे मंच क्षेत्र के भीतर से निकलने वाले कई तीरों से छेदा गया
        है। अनुभव को बढ़ाने के लिए सैकड़ों विशेष रूप से तैयार किए गए तीरों को एक साथ छत से लटका दिया गया है जो
        उस मार्मिक दृश्यको दर्शाते है।`,
      },
      {
        title: "महाभारत का युद्ध",
        image: "images/main-attractions/block-c/block-c-4.png",
        description: `यह खंड ठोस साक्ष्य प्रदान करता है जो महाभारत में वर्णित घटनाओं की ऐतिहासिक सटीकता का समर्थन करता है।
        यह खगोलीय संदर्भों, विशेष रूप से ग्रहों के संरेखण और तारों की चाल जैसी खगोलीय घटनाओं से जुड़े संदर्भों को
        इंगित करके ऐसा करता है। इन घटनाओं की समय सीमा का अनुमान लगाने के लिए आधुनिक खगोलीय उपकरणों
        का उपयोग करके इन संदर्भों का विश्लेषण किया गया है। महाभारत के युग के दौरान नक्षत्रों के दृश्य प्रतिनिधित्व,
        जो समकालीन खगोलीय तरीकों से मान्य हैं, यहां बड़े गोलाकार पर्दे पर प्रदर्शित किए गए हैं। आप यहाँ बैठकर
        तारामंडल अनुभव कर महाभारता की समयसीमा को जान सकते है।`,
      },
      {
        title: "प्राचीन कलाकृतियाँ",
        image: "images/main-attractions/block-c/block-c-5.png",
        description: `यहाँ आप गुर्जर-प्रतिहार काल की मूर्तियों के रूप में प्राचीन पत्थर की नक्काशी तकनी कों की कला का अनुभव कर
        सकते हैं, जो वर्तमान हरियाणा में कुरुक्षेत्र के पेहोवा में खुदाई की गई 8वीं और 9वीं शताब्दी ईस्वी की है। इन उत्कृष्ट
        कलाकृतियों में महाभारत के दृश्य शामिल हैं, जैसे कि पूतना वध जो की राक्षसी पुतना और भगवान कृष्ण के बीच
        टकराव को दर्शाता है, अर्जुन एक भयंकर लड़ाई की मुद्रा में, और प्रतिष्ठितजरासंध वध, मगधकेशक्तिशाली राजा
        की हार को दर्शाता है। इसके अतिरिक्त, यहाँ कई कलाकृतियाँ हैं जो योद्धाओंको उनके रथों पर गतिशील युद्ध
        मुद्राओं में अंकित हैं, जो पौराणि ककुरुक्षेत्र युद्ध की झलक पेश करती हैं। इन युद्ध के दृश्यों के साथ-साथ, आप हिंदू
        देवताओं से संबंधित मूर्तियों को देख सकते हैं, जिनमें नंदी के साथ बैठे शिव, भगवान राम और लक्ष्मण भगवान
        परशुराम से मिलते हुए, और एक मूर्ति जहां भगवान हनुमान अपने हाथों में एक पहाड़ पकड़े हुए हैं। इन जटिल रूप
        से तैयार की गई मूर्तियों को संगमरमर के आसनों पर सुंदर ढंग से प्रदर्शित किया गया है, जो दीर्घा के सौंदर्य और
        आकर्षण को बढ़ाते हैं और उसके साथ ही प्राचीन भारतीय कला और पौराणिक कथाओं में एक मनोरम अंतर्दृष्टि
        प्रदान करते हैं।`,
        label: "मुख्य आकर्षण",
      },
      // Add other items here...
    ],
  };

  return (
    <div className="main_attractions_list">
      {content[language].map((item, index) => (
        <div className="list_item" key={index}>
          <div className="attraction_image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="attranction_details">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            {item.label && <span className="main-attraction-label">{item.label}</span>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Blockc;
