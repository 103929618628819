import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiAxios } from '../../api/axios';
import { Link } from 'react-router-dom';
const Feedback = ({ language }) => {

    const [formSubmitted, setFormSubmitted] = useState(false)

    const schema = yup.object().shape({
        email: yup.string().email().required("Email is required"),
        rating: yup.string().required("Please select"),
        comment: yup.string().required("Comment is required"),
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    console.log("errors", errors)

    const onSubmitHandler = async (data) => {
        console.log({ data });
        try {
            const formData = new FormData();
            formData.append("language_id", 1);
            formData.append("rating", data.rating);
            formData.append("email", data.email);
            formData.append("comments", data.comment);

            console.log("formData", formData);
            const response = await apiAxios.post(
                "complaints/add_feedback",
                formData
            );
            setFormSubmitted(true)
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
        reset();
    };

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // This makes the scrolling smooth
          });
    },[])




    const textContent = {
        en: {
            pageTitle: 'Give Feedback',
            HomePage: 'Home',
            Feedback: 'Feedback',
            Areyousatisficed: 'Are you satisficed with our services?',
            Doyouhave: 'Do you have any thoughts you’d like to share?',
            Submit: 'Submit',
            VeryBad: 'Very Bad',
            Poor: 'Poor',
            Medium: 'Medium',
            Good: 'Good',
            VeryGood: 'Very Good',
            Excellent: 'Excellent',
            placeholders: {                
                email: 'Enter Email',
                comment: 'Type Comment here..',
            },
        },
        hi: {
            pageTitle: 'प्रतिक्रिया दें',
            HomePage: 'मुख्य पृष्ठ',
            Feedback: 'प्रतिक्रिया',
            Areyousatisficed: 'क्या आप हमारी सेवाओं से संतुष्ट हैं?',
            Doyouhave: 'क्या आपके पास कोई विचार है जिसे आप साझा करना चाहेंगे?',
            Submit: 'जमा करना',
            VeryBad: 'बहुत बुरा',
            Poor: 'घटिया',
            Medium: 'मध्यम',
            Good: 'अच्छा',
            VeryGood: 'बहुत अच्छा',
            Excellent: 'श्रेष्ठ',
            placeholders: {                
                email: 'ईमेल दर्ज करें',
                comment: 'यहां टिप्पणी टाइप करें..',
            },
        },
    };








    return (
        <div>
            <div className="inner-pages-head" style={{ backgroundImage: `url('images/about-header-bg.jpg')` }}>
                <div className="container">
                    <div className="title_and_breadcrumbs">
                        <h1>{textContent[language].pageTitle}</h1>
                        <ul>
                            <li><Link to="/">{textContent[language].HomePage}</Link></li>
                            <span></span>
                            <li>{textContent[language].pageTitle}</li>
                        </ul>
                    </div>
                    <div className="banner-logo">
                        <img src="images/mahabharat-logo.svg" alt="" />
                    </div>
                </div>
            </div>

            <section class="mt-5 mb-5">
                <div class="container">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-md-7">
                            {
                                formSubmitted && (
                                    <div className="alert alert-success alert-dismissible fade show mt-3" role="alert">
                                        <strong>Feedback Submitted Successfully!</strong>
                                    </div>
                                )
                            }
                            <div class="feedback-form">
                                <h2>{textContent[language].Feedback}</h2>
                                <p>{textContent[language].Areyousatisficed}</p>
                                <form onSubmit={handleSubmit(onSubmitHandler)}>
                                    <div class="smile-rating">
                                        <ul>
                                            <li><div class="tooltip-custom">{textContent[language].VeryBad}</div><input {...register("rating")} value={"0"} class="form-check-input" type="radio" id="flexRadioDefault1" /><span><img src="/images/rating-1.svg" alt="" /></span></li>
                                            <li><div class="tooltip-custom">{textContent[language].Poor}</div><input {...register("rating")} value={"1"} class="form-check-input" type="radio" id="flexRadioDefault2" /><span><img src="/images/rating-2.svg" alt="" /></span></li>
                                            <li><div class="tooltip-custom">{textContent[language].Medium}</div><input {...register("rating")} value={"2"} class="form-check-input" type="radio" id="flexRadioDefault3" /><span><img src="/images/rating-3.svg" alt="" /></span></li>
                                            <li><div class="tooltip-custom">{textContent[language].Good}</div><input {...register("rating")} value={"3"} class="form-check-input" type="radio" id="flexRadioDefault4" /><span><img src="/images/rating-4.svg" alt="" /></span></li>
                                            <li><div class="tooltip-custom">{textContent[language].VeryGood}</div><input {...register("rating")} value={"4"} class="form-check-input" type="radio" id="flexRadioDefault5" /><span><img src="/images/rating-5.svg" alt="" /></span></li>
                                            <li><div class="tooltip-custom">{textContent[language].Excellent}</div><input {...register("rating")} value={"5"} class="form-check-input" type="radio" id="flexRadioDefault6" /><span><img src="/images/rating-6.svg" alt="" /></span></li>
                                        </ul>
                                        <span className="form-error-msg">{errors?.rating?.message}</span>
                                    </div>
                                    <div class="input-field">
                                        <input type="email" class="form-control" placeholder={textContent[language].placeholders.email} {...register("email")} />
                                        <span className="form-error-msg">{errors?.email?.message}</span>
                                    </div>
                                    <div class="input-field">
                                        <label htmlFor="">{textContent[language].Doyouhave}</label>
                                        <textarea class="form-control" placeholder={textContent[language].placeholders.comment} {...register("comment")}></textarea>
                                        <span className="form-error-msg">{errors?.comment?.message}</span>
                                    </div>
                                    <div class="btn-grp">
                                        <button type="submit" class="submit-btn">{textContent[language].Submit}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Feedback