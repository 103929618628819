import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Blocka from './blockA';
import Blockb from './blockB';
import Blockbc from './blockBC';
import Blockc from './blockC';
import Blockd from './blockD';
import Blocke from './blockE';
import Blockf from './blockF';

const ExhibitsPage = ({ language }) => {
    const textContent = {
        en: {
            pageTitle: 'Main Attractions',
            HomePage: 'Home',
            tabs: {
                blocka: 'Block A',
                blockb: 'Block B',
                blockbc: 'Block BC',
                blockc: 'Block C',
                blockd: 'Block D',
                blocke: 'Block E',
                blockf: 'Block F',
            },
        },
        hi: {
            pageTitle: 'मुख्य आकर्षण',
            HomePage: 'मुख्य पृष्ठ',
            tabs: {
                blocka: 'खंड ए',
                blockb: 'खंड बी',
                blockbc: 'खंड बीसी',
                blockc: 'खंड सी',
                blockd: 'खंड डी',
                blocke: 'खंड ई',
                blockf: 'खंड एफ',
            },
        },
    };
    

    return (
        <>
            <div
                className="inner-pages-head"
                style={{ backgroundImage: `url('images/about-header-bg.jpg')` }}
            >
                <div className="container">
                    <div className="title_and_breadcrumbs">
                        <h1>{textContent[language].pageTitle}</h1>
                        <ul>
                            <li>
                                <Link to="/">{textContent[language].HomePage}</Link>
                            </li>
                            <span></span>
                            <li>{textContent[language].pageTitle}</li>
                        </ul>
                    </div>
                    <div className="banner-logo">
                        <img src="images/mahabharat-logo.svg" alt="" />
                    </div>
                </div>
            </div>

            <div className="page_content_wrapper main_attractions_tabs">
                <div className="container">
                    <div className="content_wrapper">
                        <Tabs defaultActiveKey="blocka" id="uncontrolled-tab-example">
                            <Tab
                                eventKey="blocka"
                                title={textContent[language].tabs.blocka}
                            >
                                <Blocka language={language} />
                            </Tab>
                            <Tab
                                eventKey="blockb"
                                title={textContent[language].tabs.blockb}
                            >
                                <Blockb language={language} />
                            </Tab>
                            <Tab
                                eventKey="blockbc"
                                title={textContent[language].tabs.blockbc}
                            >
                                <Blockbc language={language} />
                            </Tab>
                            <Tab
                                eventKey="blockc"
                                title={textContent[language].tabs.blockc}
                            >
                                <Blockc language={language} />
                            </Tab>
                            <Tab
                                eventKey="blockd"
                                title={textContent[language].tabs.blockd}
                            >
                                <Blockd language={language} />
                            </Tab>
                            <Tab
                                eventKey="blocke"
                                title={textContent[language].tabs.blocke}
                            >
                                <Blocke language={language} />
                            </Tab>
                            <Tab
                                eventKey="blockf"
                                title={textContent[language].tabs.blockf}
                            >
                                <Blockf language={language} />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExhibitsPage;
