import React from 'react';
import './style/style.css';
import { Link } from 'react-router-dom';

const content = {
    en: [
        {
            title: "About Mahabharat Museum",
            HomePage: 'Home',
            p1: 'Jyotisar, in Kurukshetra Haryana is the sacred land where Lord Krishna bestowed the eternal wisdom of the Bhagavad Gita to Arjuna. Nestled in the historic 48 Kos area of Kurukshetra, Jyotisar forms an integral part of the Krishna Circuit.',
            p2: 'At its heart lies The Mahabharata Anubhav Kendra, an extraordinary museum and experience centre commissioned by the Haryana Tourism Corporation. This unique Museum brings the epic legacy of the Mahabharata to life through a dynamic blend of storytelling and cutting-edge technology. This remarkable museum is a treasure trove of Mahabharata’s grandeur and timeless teachings.',
            p3: 'Spread over 1 lakh square feet the museum has 5 galleries which have different sections that mesmerize the viewers from the time they enter.',
            p4: 'The galleries are connected ingeniously, each telling stories in different styles and formats amalgamating the rich past and the invigorating present. The project is designed and curated by <div class="image_tooltip"><span>Mr Sanjeev Pasricha</span><div class="tooltip_wrapper"><img src="images/sanjeev.png" alt="" /></div></div>, whose 25 years of experience in museums comes alive in this dynamic and alive museum. The script for the museum is written by Padma Shree recipient <div class="image_tooltip"><span>Dr. Chandraprakash Dwivedi.</span><div class="tooltip_wrapper"><img src="images/chanderparkash.png" alt="" /></div></div><div class="image_tooltip"><span>Dr. Harish Bhimani’s</span><div class="tooltip_wrapper"><img src="images/harish.png" alt="" /></div></div> voice echoes the epic in a profound and graceful manner. Artisans from Kolkata, Varanasi, Chennai, and Gujarat have worked relentlessly in the museum to curate it.',
            p5: 'From Kinetic Installations to multi-layered sets, projection mapping to high relief murals, from holographic projections to hyper-realistic figures and a spellbinding 360-degree dome projection, these technologies and stories will transfer the viewers to the past magically!',
            p6: 'The Mahabharat Anubhav Kendra will draw the entire world towards itself and instill pride in the hearts of every visitor. An experience truly out of this world!',
        },
    ],
    hi: [
        {
            title: "महाभारत संग्रहालय के बारे में",
            HomePage: 'मुख्य पृष्ठ',
            p1: 'हरियाणा के कुरुक्षेत्र में ज्योतिसर वह पवित्र भूमि है जहाँ भगवान कृष्ण ने अर्जुन को भगवद गीता का शाश्वत ज्ञान दिया था। कुरुक्षेत्र के ऐतिहासिक 48 कोस क्षेत्र में बसा ज्योतिसर कृष्ण सर्किट का एक अभिन्न अंग है।',
            p2: 'इसके मध्य में महाभारत अनुभव केंद्र है, जो हरियाणा पर्यटन निगम द्वारा संचालित एक असाधारण संग्रहालय और अनुभव केंद्र है। यह अनोखा संग्रहालय महाभारत की महाकाव्य विरासत को कहानियों और अत्याधुनिक तकनीक के गतिशील मिश्रण के माध्यम से जीवंत करता है। यह उल्लेखनीय संग्रहालय महाभारत की भव्यता और कालातीत शिक्षाओं का खजाना है।',
            p3: '1 लाख वर्ग फीट में फैले संग्रहालय में 5 गैलरी हैं जिनमें अलग-अलग खंड हैं जो दर्शकों को प्रवेश करते ही मंत्रमुग्ध कर देते हैं।',
            p4: 'ये गैलरी सरलता से जुड़ी हुई हैं, प्रत्येक गैलरी अलग-अलग शैलियों और प्रारूपों में कहानियाँ बताती है जो समृद्ध अतीत और स्फूर्तिदायक वर्तमान को जोड़ती हैं। इस परियोजना को <div class="image_tooltip"><span>श्री संजीव पसरीचा</span><div class="tooltip_wrapper"><img src="images/sanjeev.png" alt="" /></div></div> द्वारा डिज़ाइन और क्यूरेट किया गया है, जिनका संग्रहालयों में 25 वर्षों का अनुभव इस गतिशील और अनोखे संग्रहालय में जीवंत हो उठता है। संग्रहालय की पटकथा पद्मश्री <div class="image_tooltip"><span>डॉ. चंद्रप्रकाश द्विवेदी</span><div class="tooltip_wrapper"><img src="images/chanderparkash.png" alt="" /></div></div> ने लिखी है। <div class="image_tooltip"><span>डॉ. हरीश भीमनी</span><div class="tooltip_wrapper"><img src="images/harish.png" alt="" /></div></div> की आवाज़ में महाकाव्य की गहन और सुंदर प्रतिध्वनि है। कोलकाता, वाराणसी, चेन्नई और गुजरात के कारीगरों ने संग्रहालय को संवारने के लिए अथक परिश्रम किया है।',
            p5: 'काइनेटिक इंस्टॉलेशन से लेकर मल्टी लेयर्ड सेट, प्रोजेक्शन मैपिंग से लेकर हाई रिलीफ म्यूरल, होलोग्राफिक प्रोजेक्शन से लेकर हाइपर रियलिस्टिक फिगर और मंत्रमुग्ध करने वाले 360-डिग्री डोम प्रोजेक्शन तक, ये तकनीकें और कहानियाँ दर्शकों को जादुई तरीके से अतीत में ले जाएँगी!',
            p6: 'महाभारत अनुभव केंद्र- पूरी दुनिया को अपनी ओर आकर्षित करेगा और हर आगंतुक के दिल में गर्व की भावना भर देगा। वाकई एक ऐसा अनुभव जो इस दुनिया से बाहर है!',
        },
    ],
};

const AboutMuseum = ({ language }) => {
    const data = content[language][0]; // Dynamically get the content based on the language prop

    return (
        <>
            <div
                className="inner-pages-head"
                style={{ backgroundImage: `url('images/about-header-bg.jpg')` }}
            >
                <div className="container">
                    <div className="title_and_breadcrumbs">
                        <h1>{data.title}</h1>
                        <ul>
                            <li>
                                <Link to="/">{data.HomePage}</Link>
                            </li>
                            <span></span>
                            <li>{data.title}</li>
                        </ul>
                    </div>
                    <div className="banner-logo">
                        <img src="images/mahabharat-logo.svg" alt="" />
                    </div>
                </div>
            </div>

            <div id="aboutsection" className="page_content_wrapper">
                <div className="container">
                    <div className="content_wrapper">
                        <h3>{data.title}</h3>
                        <div className="about_image_content">
                            <img src="images/about-content-image.png" alt="" />
                        </div>
                        {Object.keys(data).map(
                            (key, index) =>
                                key.startsWith('p') && (
                                    <p
                                        key={index}
                                        dangerouslySetInnerHTML={{
                                            __html: data[key],
                                        }}
                                    ></p>
                                )
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutMuseum;
