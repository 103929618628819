import React, { useEffect, useState } from 'react'
import MuseumExhibits from '../../components/Home/museumExhibits'
import VistorInfo from '../../components/Home/vistorInfo'
import { ColorRing } from 'react-loader-spinner'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { useQuery } from '@tanstack/react-query'
import { getHomePageData } from '../../services/homeServices'
import "./home-style.css"
import { Link } from 'react-router-dom'
import ImageGallery from '../../components/imagegallery/ImageGallery'

const Home = ({ language }) => {


  const textContent = {
    en: {
      MuseumataGlanceHeading: 'Museum at a Glance',
      MuseumataGlancedescription:'To know the true soul of bharat , you need to know Mahabharat. With this thought in mind the Museum of Mahahbharat has been created to engage audience in its layered stories and immersive technologies . Worlds first dynamic museum in Jyotisar , that will take your experience to a new pinnacle.',
      MainAttractionsHeading:'Main Attractions',
      Lightandsoundshow:'Light and sound show',
      AbhigyanShakuntalam:'Abhigyan Shakuntalam',
      AbhigyanShakuntalamDec:'The story of Shakuntala will come alive highlighting the virtues of patience, forgiveness, and the resilience of the human spirit. Discover how India came to be known as "Bharatavarsha” named after Bharata- a brave and powerful ruler',
      MahabharatkaMahanayak:'Mahabharat ka Mahanayak',
      MahabharatkaMahanayakDec:'The life of Lord Krishna, his Avatars and his pivotal presence in the Mahabharata will enthral you. Discover the various roles he played from being a guide, protector, motivator, strategist while steering the course of events and upholding the cosmic order. ',
      MuseumJourneyH:'Museum Journey',
      MuseumGalleriesH:'Museum Galleries',
      bookTIcketBtn:'Book Tickets',
    },
    hi: {
      MuseumataGlanceHeading: 'संग्रहालय की एक झलक',
      MuseumataGlancedescription:'भारत की सच्ची आत्मा को जानने के लिए, आपको महाभारत को जानना होगा। इसी सोच को ध्यान में रखते हुए महाभारत संग्रहालय बनाया गया है, ताकि दर्शकों को इसकी परतदार कहानियों और इमर्सिव तकनीकों से जोड़ा जा सके। ज्योतिसर में दुनिया का पहला गतिशील संग्रहालय, जो आपके अनुभव को एक नए शिखर पर ले जाएगा।',
      MainAttractionsHeading:'मुख्य आकर्षण',
      Lightandsoundshow:'लाइट एंड साउंड शो',
      AbhigyanShakuntalam:'अभिज्ञान शाकुंतलम',
      AbhigyanShakuntalamDec:'शकुंतला की कहानी धैर्य, क्षमा और मानवीय भावना के लचीलेपन के गुणों को उजागर करते हुए जीवंत हो जाएगी। जानें कि कैसे भारत को "भारतवर्ष" के नाम से जाना जाने लगा, जिसका नाम भरत के नाम पर रखा गया - एक बहादुर और शक्तिशाली शासक। ',
      MahabharatkaMahanayak:'महाभारत के महानायक',
      MahabharatkaMahanayakDec:'भगवान कृष्ण का जीवन, उनके अवतार और महाभारत में उनकी महत्वपूर्ण उपस्थिति आपको रोमांचित कर देगी। घटनाओं के क्रम को नियंत्रित करते हुए मार्गदर्शक, रक्षक, प्रेरक, रणनीतिकार से लेकर ब्रह्मांडीय व्यवस्था को बनाए रखने तक उन्होंने जो विभिन्न भूमिकाएँ निभाईं, उन्हें जानें। ',
      MuseumJourneyH:'संग्रहालय यात्रा',
      MuseumGalleriesH:'संग्रहालय गैलरी',
      bookTIcketBtn:'टिकट खरीदें',
    },
  };
  

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // This makes the scrolling smooth
    });
  }, [])

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["home"],
    queryFn: ({ signal }) => getHomePageData(signal),
  })

  const heroslide = {
    margin: 0,
    responsiveClass: true,
    nav: true,
    dots:false,
    autoplay: true,
    loop: true,
    autoplayTimeout: 3000,
    animateOut: "fadeOut", // Fade animation for slides
    mouseDrag: false, // Disable mouse drag
    touchDrag: false, // Disable touch drag
    responsive: {
      0: { items: 1 },
      600: { items: 1 },
      1000: { items: 1 }
    },
  };

  const showslider = {
    margin: 0,
    responsiveClass: true,
    nav: true,
    dots:false,
    //autoplay: true,
    loop: true,
    autoplayTimeout: 3000,
    animateOut: "fadeOut", // Fade animation for slides
    mouseDrag: false, // Disable mouse drag
    touchDrag: false, // Disable touch drag
    responsive: {
      0: { items: 1 },
      600: { items: 1 },
      1000: { items: 1 }
    },
  };



  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlayClick = () => {
    setIsPlaying(true);
  };



  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{ height: "700px" }}>
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
        />
      </div>
    )
  }

  if (data) {
    return (
      <>

          <div className="main-slider">            
            <div className="carousel-container">
              <OwlCarousel className="owl-theme" {...heroslide}>
                <div className="item"><img src="/images/hero-slide-1.jpg" alt="" /></div>
                <div className="item"><img src="/images/hero-slide-2.jpg" alt="" /></div>
                <div className="item"><img src="/images/hero-slide-3.jpg" alt="" /></div>
                <div className="item"><img src="/images/hero-slide-4.jpg" alt="" /></div>
                <div className="item"><img src="/images/hero-slide-5.jpg" alt="" /></div>
                <div className="item"><img src="/images/hero-slide-6.jpg" alt="" /></div>
              </OwlCarousel>
            </div>
          </div>


          <section className='MuseumataGlance'>

            <h2>{textContent[language].MuseumataGlanceHeading}</h2>
            <p>{textContent[language].MuseumataGlancedescription}</p>
            
            <div className="museum-image">
            <img src="/images/museum-glance.jpg" alt="" />
            </div>
          </section>


          <section className='museum-tour'>
            <div className="container">
              <div className="video-tour">
                <div className="border-image"><img src="/images/video-border-frame.svg" alt="" /></div>
                <div className={`video-frame ${isPlaying ? 'open' : ''}`} onClick={handlePlayClick}>
                  {!isPlaying && (
                    <div className="cover-image"><img src="/images/tour-video-cover.jpg" alt="" /> <span className='play-video-trigger'><img src="/images/videoplay-icon.svg" alt="" /></span></div>
                  )}
                  <iframe src={`https://www.youtube.com/embed/fjG0WTGVdvU?rel=0&showinfo=0${isPlaying ? '&autoplay=1' : ''}`} frameborder="0" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </section>


          {
            data.uniqueBlocksExhibits && <MuseumExhibits homeExhibits={data.uniqueBlocksExhibits} language={language} />
          }


          <div className="show-slider">
            <div className="section-heading"><h2>{textContent[language].Lightandsoundshow}</h2></div>           
            <div className="carousel-container with-custom-arrow">
              <OwlCarousel className="owl-theme" {...showslider} key={language}>
                <div className="item">
                  <img src="/images/show-image-1.jpg" alt="" />
                  <div className="slider-caption">
                    <div className="container">
                      <h3>{textContent[language].AbhigyanShakuntalam}</h3>
                      <p>{textContent[language].AbhigyanShakuntalamDec}</p>
                      <Link to="#">{textContent[language].bookTIcketBtn}</Link>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <img src="/images/show-image-2.jpg" alt="" />
                  <div className="slider-caption">
                    <div className="container">
                      <h3>{textContent[language].MahabharatkaMahanayak}</h3>
                      <p>{textContent[language].MahabharatkaMahanayakDec}</p>
                      <Link to="#">{textContent[language].bookTIcketBtn}</Link>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>




          <section className='museum-journey museum-tour'>
            <div className="container">
              <h3>{textContent[language].MuseumJourneyH}</h3>
              <div className="video-tour">
                <div className="border-image"><img src="/images/video-border-frame.svg" alt="" /></div>
                <div className={`video-frame ${isPlaying ? 'open' : ''}`} onClick={handlePlayClick}>
                  {!isPlaying && (
                    <div className="cover-image"><img src="/images/museum-joirney-cover.jpg" alt="" /> <span className='play-video-trigger'><img src="/images/videoplay-icon.svg" alt="" /></span></div>
                  )}
                  <iframe src={`https://www.youtube.com/embed/IxNKPmu5Ew0?rel=0&showinfo=0${isPlaying ? '&autoplay=1' : ''}`} frameborder="0" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </section>



          <section className='MuseumGalleries'>
            <div className="container">
              <h2>{textContent[language].MuseumGalleriesH}</h2>
            </div>
            <div className="container">
              <ImageGallery language={language}/>
            </div>
          </section>

          


          <VistorInfo language={language} />





      </>
    )
  }

  if(isError){
    return <div style={{textAlign:"center"}}>{error.message}</div>
  }



}

export default Home