// ImageGallery.js
import React from 'react';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-zoom.css';

import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const ImageGallery = ({ language }) => {
  const textContent = {
    en: {
        BlockA:'Block A',        
        BlockB:'Block B',
        BlockBC:'Block BC',
        BlockC:'Block C',        
        BlockD:'Block D',        
        BlockE:'Block E',        
        BlockF:'Block F',        
    },
    hi: {
      BlockA:'ब्लॉक - ए',        
      BlockB:'ब्लॉक - बी',
      BlockBC:'ब्लॉक - बी सी',        
      BlockC:'ब्लॉक - सी',        
      BlockD:'ब्लॉक - डी',        
      BlockE:'ब्लॉक - ई',        
      BlockF:'ब्लॉक - एफ',
    },
  };



  const imageGroups = [
    {
      title: textContent[language].BlockA,
      images: [
        { src: '/images/gallery-images/block-a/15.png', thumb: '/images/gallery-images/block-a/15.png'},
        { src: '/images/gallery-images/block-a/1.png', thumb: '/images/gallery-images/block-a/1.png'},
        { src: '/images/gallery-images/block-a/2.png', thumb: '/images/gallery-images/block-a/2.png'},
        { src: '/images/gallery-images/block-a/3.png', thumb: '/images/gallery-images/block-a/3.png'},
        { src: '/images/gallery-images/block-a/4.png', thumb: '/images/gallery-images/block-a/4.png'},
        { src: '/images/gallery-images/block-a/5.png', thumb: '/images/gallery-images/block-a/5.png'},
        { src: '/images/gallery-images/block-a/6.png', thumb: '/images/gallery-images/block-a/6.png'},
        { src: '/images/gallery-images/block-a/7.png', thumb: '/images/gallery-images/block-a/7.png'},
        { src: '/images/gallery-images/block-a/8.png', thumb: '/images/gallery-images/block-a/8.png'},
        { src: '/images/gallery-images/block-a/9.png', thumb: '/images/gallery-images/block-a/9.png'},
        { src: '/images/gallery-images/block-a/10.png', thumb: '/images/gallery-images/block-a/10.png'},
        { src: '/images/gallery-images/block-a/11.png', thumb: '/images/gallery-images/block-a/11png'},
        { src: '/images/gallery-images/block-a/12.png', thumb: '/images/gallery-images/block-a/12.png'},
        { src: '/images/gallery-images/block-a/13.png', thumb: '/images/gallery-images/block-a/13.png'},
        { src: '/images/gallery-images/block-a/14.png', thumb: '/images/gallery-images/block-a/14.png'},        
      ],
    },
    {
      title: textContent[language].BlockB,
      images: [
        { src: '/images/gallery-images/block-b/15.png', thumb: '/images/gallery-images/block-b/15.png'},
        { src: '/images/gallery-images/block-b/1.png', thumb: '/images/gallery-images/block-b/1.png'},
        { src: '/images/gallery-images/block-b/2.png', thumb: '/images/gallery-images/block-b/2.png'},
        { src: '/images/gallery-images/block-b/3.png', thumb: '/images/gallery-images/block-b/3.png'},
        { src: '/images/gallery-images/block-b/4.png', thumb: '/images/gallery-images/block-b/4.png'},
        { src: '/images/gallery-images/block-b/5.png', thumb: '/images/gallery-images/block-b/5.png'},
        { src: '/images/gallery-images/block-b/6.png', thumb: '/images/gallery-images/block-b/6.png'},
        { src: '/images/gallery-images/block-b/7.png', thumb: '/images/gallery-images/block-b/7.png'},
        { src: '/images/gallery-images/block-b/8.png', thumb: '/images/gallery-images/block-b/8.png'},
        { src: '/images/gallery-images/block-b/9.png', thumb: '/images/gallery-images/block-b/9.png'},
        { src: '/images/gallery-images/block-b/10.png', thumb: '/images/gallery-images/block-b/10.png'},
        { src: '/images/gallery-images/block-b/11.png', thumb: '/images/gallery-images/block-b/11png'},
        { src: '/images/gallery-images/block-b/12.png', thumb: '/images/gallery-images/block-b/12.png'},
        { src: '/images/gallery-images/block-b/13.png', thumb: '/images/gallery-images/block-b/13.png'},
        { src: '/images/gallery-images/block-b/14.png', thumb: '/images/gallery-images/block-b/14.png'},   
      ],
    },
    {
      title: textContent[language].BlockBC,
      images: [        
        { src: '/images/gallery-images/block-bc/4.png', thumb: '/images/gallery-images/block-bc/4.png'},
        { src: '/images/gallery-images/block-bc/1.png', thumb: '/images/gallery-images/block-bc/1.png'},
        { src: '/images/gallery-images/block-bc/2.png', thumb: '/images/gallery-images/block-bc/2.png'},
        { src: '/images/gallery-images/block-bc/3.png', thumb: '/images/gallery-images/block-bc/3.png'},
      ],
    },
    {
      title: textContent[language].BlockC,
        images: [
          { src: '/images/gallery-images/block-c/12.png', thumb: '/images/gallery-images/block-c/12.png'},
          { src: '/images/gallery-images/block-c/1.png', thumb: '/images/gallery-images/block-c/1.png'},
          { src: '/images/gallery-images/block-c/2.png', thumb: '/images/gallery-images/block-c/2.png'},
          { src: '/images/gallery-images/block-c/3.png', thumb: '/images/gallery-images/block-c/3.png'},
          { src: '/images/gallery-images/block-c/4.png', thumb: '/images/gallery-images/block-c/4.png'},
          { src: '/images/gallery-images/block-c/5.png', thumb: '/images/gallery-images/block-c/5.png'},
          { src: '/images/gallery-images/block-c/6.png', thumb: '/images/gallery-images/block-c/6.png'},
          { src: '/images/gallery-images/block-c/7.png', thumb: '/images/gallery-images/block-c/7.png'},
          { src: '/images/gallery-images/block-c/8.png', thumb: '/images/gallery-images/block-c/8.png'},
          { src: '/images/gallery-images/block-c/9.png', thumb: '/images/gallery-images/block-c/9.png'},
          { src: '/images/gallery-images/block-c/10.png', thumb: '/images/gallery-images/block-c/10.png'},
          { src: '/images/gallery-images/block-c/11.png', thumb: '/images/gallery-images/block-c/11png'},
        ],
    },
    {
      title: textContent[language].BlockD,
        images: [
          { src: '/images/gallery-images/block-d/9.png', thumb: '/images/gallery-images/block-d/9.png'},
          { src: '/images/gallery-images/block-d/1.png', thumb: '/images/gallery-images/block-d/1.png'},
          { src: '/images/gallery-images/block-d/2.png', thumb: '/images/gallery-images/block-d/2.png'},
          { src: '/images/gallery-images/block-d/3.png', thumb: '/images/gallery-images/block-d/3.png'},
          { src: '/images/gallery-images/block-d/4.png', thumb: '/images/gallery-images/block-d/4.png'},
          { src: '/images/gallery-images/block-d/5.png', thumb: '/images/gallery-images/block-d/5.png'},
          { src: '/images/gallery-images/block-d/6.png', thumb: '/images/gallery-images/block-d/6.png'},
          { src: '/images/gallery-images/block-d/7.png', thumb: '/images/gallery-images/block-d/7.png'},
          { src: '/images/gallery-images/block-d/8.png', thumb: '/images/gallery-images/block-d/8.png'},
          { src: '/images/gallery-images/block-d/10.png', thumb: '/images/gallery-images/block-d/10.png'},
          { src: '/images/gallery-images/block-d/11.png', thumb: '/images/gallery-images/block-d/11png'},
          { src: '/images/gallery-images/block-d/12.png', thumb: '/images/gallery-images/block-d/12.png'},
        ],
    },
    {
      title: textContent[language].BlockE,
        images: [
          { src: '/images/gallery-images/block-e/7.png', thumb: '/images/gallery-images/block-e/7.png'},
          { src: '/images/gallery-images/block-e/1.png', thumb: '/images/gallery-images/block-e/1.png'},
          { src: '/images/gallery-images/block-e/2.png', thumb: '/images/gallery-images/block-e/2.png'},
          { src: '/images/gallery-images/block-e/3.png', thumb: '/images/gallery-images/block-e/3.png'},
          { src: '/images/gallery-images/block-e/4.png', thumb: '/images/gallery-images/block-e/4.png'},
          { src: '/images/gallery-images/block-e/5.png', thumb: '/images/gallery-images/block-e/5.png'},
          { src: '/images/gallery-images/block-e/6.png', thumb: '/images/gallery-images/block-e/6.png'},
        ],
    },
    {
      title: textContent[language].BlockF,
        images: [
          { src: '/images/gallery-images/block-f/3.png', thumb: '/images/gallery-images/block-f/3.png'},
          { src: '/images/gallery-images/block-f/1.png', thumb: '/images/gallery-images/block-f/1.png'},
          { src: '/images/gallery-images/block-f/2.png', thumb: '/images/gallery-images/block-f/2.png'},
        ],
    },
  ];


  


  return (
    <div className='row justify-content-center'>
      {imageGroups.map((group, index) => (
        <div className='col-lg-4' key={index}>
          <div className='gallery-item'>
            <span>{group.title}</span>
            <LightGallery
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
                mode="lg-fade"
            >
                {group.images.map((image, idx) => (
                <a
                    key={idx}
                    href={image.src}
                    data-lg-size="1406-1390"
                    data-lg-thumbnail={image.thumb}
                >
                    <img src={image.thumb} alt={image.alt} />
                </a>
                ))}
            </LightGallery>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;
