import React, { useEffect, useMemo, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './style.css';
import moment from 'moment';
import { getNextNDatesFormatted } from '../../components/common';
import { formatShowSelectedDate } from '../../components/common';
import UserDetailsModels from '../../components/booking/userdetailsmodels';
import { apiAxios, bookingApi } from '../../api/axios';
import { Link, useNavigate } from 'react-router-dom';
import BookingListSkelton from './skelton/bookingListSkelton';
import { useLocation } from 'react-router-dom';

import UserDetailsProccedModels from '../../components/booking/userdetailsproccedmodels ';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const BookingPage = ({ language }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    const navigate = useNavigate();
    let currentDate = moment();
    const [loading, setloading] = useState(false)
    const [passBooking, setPassBooking] = useState(false)
    const [entryPassModalShow, setEntryPassModalShow] = React.useState(false);
    const [formatedDates, setFrmatedDates] = useState([])
    const [modalShow, setModalShow] = useState(false);

    const [userDetails, setUserDetails] = useState(null)
    const [userModalshow, setUserModalShow] = useState(false)
    const [selectedShowsWorkingHours, setSelectedShowsWorkingHours] = useState([])
    const [error, setError] = useState("");
    const [activeClassToggle, setActiveClassToggle] = useState(false);

    const [adultMember, setAdultMember] = useState(0);
    const [childMember, setChildMember] = useState(0);
    const [foreignerMember, setForeignerMember] = useState(0);

    const [bookingShowError, setBookingShowError] = useState("");

    const [userSelectShowTime, setUserSelectShowTime] = useState(null);

    const [selectedDate, setSelectedDate] = useState("");
    const [selectedDay, setSelectedDay] = useState("");
    const [shows, setShows] = useState([])
    const [addedEntryPassMember, setAddedEntryPassMember] = useState(false);
    const [memberBookShows, setMemberBookShows] = useState([])
    const [currentLocationId, setCurrentLocationId] = useState("");

    const [entryPassdateFormat, setEntryPassdateFormat] = useState(null);


    const passCurrentDate = moment().format('DD-MM-YYYY');
    const [calendarSelectedDate, setCalendarSelectedDate] = useState(dayjs());


    console.log("passCurrentDatepassCurrentDate", passCurrentDate)
    console.log("passCurrentDatepassCurrentDate calendarSelectedDate", calendarSelectedDate)


    const [userDetailsProceedModals, setUserDetailsProceedModals] = useState(false)


    const formatCalendarDate = useMemo(() => {
        return moment(calendarSelectedDate.toDate()).format('DD MMM YYYY')
    }, [calendarSelectedDate])

    console.log("entryPassdateFormat", entryPassdateFormat)





    const getShows = async () => {
        setloading(true)
        try {
            const res = await apiAxios.get("locations/getLocations?language_id=1&type=show");
            console.log("resresresresres", res);
            const currentDate = moment().format('DD MMMM YYYY');
            const parsedDate = moment(currentDate, 'DD MMMM YYYY'); // Parse the input date
            const dayOfWeek = parsedDate.format('dddd'); // Format to display the day of the week
            setSelectedDay(dayOfWeek);
            setSelectedDate(currentDate)
            const entryPassdateFormat = formatShowSelectedDate(currentDate);

            console.log("entryPassdateFormatentryPassdateFormat", entryPassdateFormat)
            setEntryPassdateFormat(entryPassdateFormat);
            const data = res.data.data.map(item => ({
                ...item,
                AdultMemberBooking: 0,
                ChildMemberBooking: 0,
                ForeignerMemberBooking: 0,
                selectedDate: currentDate,
                selectedDay: dayOfWeek,
                selectedWorkingHours: null,
                showBookingButton: true,
                openShowTimingSection: false
            }));
            setShows(data);
        } catch (err) {
            console.log("err", err)
        } finally {
            setloading(false)
        }
    }


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // This makes the scrolling smooth
        });
        const dates = getNextNDatesFormatted()
        setFrmatedDates(dates)

        if (type === "edit") {
            const storageObj = localStorage.getItem('bookingData');
            const EntryPassObj = localStorage.getItem('EntryPassData');
            if (storageObj) {
                const parsedData = JSON.parse(storageObj);
                const calenderDate = moment(parsedData.selectedDate).format("DD-MM-YYYY");
                const format = dayjs(calenderDate, "DD-MM-YYYY")
                setCalendarSelectedDate(format)

                setSelectedDate(parsedData.selectedDate);
                setShows(parsedData.shows);
                setAddedEntryPassMember(parsedData.addedEntryPassMember);
                setAdultMember(parsedData.adultMember);
                setChildMember(parsedData.childMember);
                setForeignerMember(parsedData.foreignerMember);
                setMemberBookShows(parsedData.memberBookShows);
                setFrmatedDates(parsedData.formatedDates)
                setUserDetails(parsedData.userDetails);
                setEntryPassdateFormat(parsedData.entryPassdateFormat)
                // setAddedEntryPassMember(parsedData.addedEntryPassMember);
            } else if (EntryPassObj) {

                const data = JSON.parse(EntryPassObj);
                const calenderDate = moment(data.selectedDate).format("DD-MM-YYYY");
                const format = dayjs(calenderDate, "DD-MM-YYYY")
                setCalendarSelectedDate(format)

                setSelectedDate(data.selectedDate);
                setAdultMember(data.adultMember);
                setChildMember(data.childMember);
                setForeignerMember(data.foreignerMember);
                setAddedEntryPassMember(data.addedEntryPassMember);
                setFrmatedDates(data.formatedDates)
                setUserDetails(data.userDetails);
                setShows(data.shows);
                setEntryPassdateFormat(data.entryPassdateFormat)

            } else {
                getShows();
                localStorage.removeItem("bookingData");
                localStorage.removeItem("EntryPassData");
            }
        } else {
            getShows();
            localStorage.removeItem("bookingData");
            localStorage.removeItem("EntryPassData");
            localStorage.removeItem("objApiData");
        }

        const handleBeforeUnload = () => {
            localStorage.removeItem("bookingData");
            localStorage.removeItem("EntryPassData");
            localStorage.removeItem("objApiData");
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [type]);


    const daysName = useMemo(() => {
        let daysNames = [];
        for (let i = 0; i < 7; i++) {
            daysNames.push(currentDate.format('dddd')); // 'dddd' format gives the full name of the day
            currentDate.add(1, 'days'); // Move to the next day
        }
        return daysNames
    }, [])

    const handleShowTiming = async (workingHours, id) => {
        setCurrentLocationId(id)
        const formattedDate = moment(selectedDate, "DD MMM YYYY").format("DD-MM-YYYY");
        console.log("idddddd", id, selectedDate)

        const formdata = new FormData();
        formdata.append("location_id", id);
        formdata.append("date", formattedDate);

        try {
            const res = await apiAxios.post("locations/show_availability", formdata);
            console.log("resresres", res.data.data)
            const data = res.data.data
            if (data.hasOwnProperty("timings")) {
                const updatedTimings = data.timings.map((item) => {
                    return { ...item, members: item.members || 0 }
                });
                data.timings = updatedTimings;

                const updatedShows = shows.map((item) => {
                    if (item.location_id === id && item.selectedWorkingHours === null) {
                        return { ...item, selectedWorkingHours: data, selectedWorkingHoursCopy: data, openShowTimingSection: true }
                    } else if (item.location_id === id) {
                        return { ...item, openShowTimingSection: true }
                    }
                    return item;
                });




                setUserSelectShowTime(res.data.data);
                setShows(updatedShows)
                // setModalShow(true)
            } else {
                alert("The 'timing' key does not exist")
            }



        } catch (err) {
            console.log(err)
        }




    }

    const handleBookingDate = (id) => {


        const updatedDates = formatedDates.map(date => ({
            ...date,
            active: date.id === id
        }));
        const date = updatedDates.find((item) => item.active === true).formattedDate;
        const parsedDate = moment(date, 'DD MMMM YYYY'); // Parse the input date
        const dayOfWeek = parsedDate.format('dddd'); // Format to display the day of the week

        const updatedArr = shows.map((item) => {
            if (item.selectedWorkingHours && item.selectedWorkingHours.timings) {
                const updatedTimings = item.selectedWorkingHours.timings.map((timing) => ({
                    ...timing,
                    members: 0,
                    active: false,
                    booked: false
                }));

                return {
                    ...item,
                    selectedWorkingHours: {
                        ...item.selectedWorkingHours,
                        timings: updatedTimings
                    }
                };
            } else {
                return item;
            }
        });

        console.log("updatedArr", updatedArr);


        // shows.selectedWorkingHours = updatedTimes;

        const updatedShows = updatedArr.map(item => ({ ...item, selectedDate: date, selectedDay: dayOfWeek }));
        setSelectedDay(dayOfWeek)
        setShows(updatedShows);
        setFrmatedDates(updatedDates);
        setSelectedDate(date)
        setMemberBookShows([])
        // setActiveClassToggle(!activeClassToggle);
        // getShows()
    }

    const handleSelectedhowTiming = (obj) => {
        const updatedShows = shows.map((item) => {
            if (item.location_id === obj.location_id) {
                return { ...item, selectedtiming: obj.selectedtiming, available: obj.available, userSelectedtiming: obj.userSelectedtiming, selectedStartTiming: obj.selectedStartTiming, selectedWorkingHours: obj.selectedWorkingHours, selectedWorkingHoursCopy: obj.selectedWorkingHours, addMembers: true, showBookingButton: false }
            }
            return item;
        });
        setShows(updatedShows);
        setModalShow(false);
    }




    const handleAddMemberForPass = () => {
        if (adultMember === 0 && foreignerMember === 0) {
            setError("Aleast one adult member should be selected");
        }
        else {
            setAddedEntryPassMember(true)
            setBookingShowError("")

        }
    }

    const handleShowAdultMemberIncrement = (id) => {
        const updatedShows = shows.map(show => {
            if (show.location_id === id && show.AdultMemberBooking < adultMember) {
                const updatedTime = show.selectedWorkingHours.timings.map(timeSlot => {
                    if (timeSlot.active) {
                        console.log("timeSlot.members", timeSlot.members)
                        return { ...timeSlot, members: timeSlot.members + 1 };
                    }
                    return timeSlot;
                });
                const check = updatedTime.find((item) => item?.booked === true);
                if (check.members <= +check.available) {
                    return {
                        ...show,
                        AdultMemberBooking: show.AdultMemberBooking + 1,
                        selectedWorkingHours: { ...show.selectedWorkingHours, timings: updatedTime }
                    };
                }
                console.log("updatedTime", updatedTime)

                // return { ...show, AdultMemberBooking: show.AdultMemberBooking + 1 }; 
            }
            return show;
        });
        setShows(updatedShows);
    }

    const handleShowAdultMemberDecrement = (id) => {


        const updatedShows = shows.map(show => {
            if (show.location_id === id && show.AdultMemberBooking > 0) {
                const updatedTime = show.selectedWorkingHours.timings.map(timeSlot => {
                    if (timeSlot.active) {
                        return { ...timeSlot, members: timeSlot.members - 1 };
                    }
                    return timeSlot;
                });
                const check = updatedTime.find((item) => item?.booked === true);
                if (check.members <= +check.available) {
                    return {
                        ...show,
                        AdultMemberBooking: show.AdultMemberBooking - 1,
                        selectedWorkingHours: { ...show.selectedWorkingHours, timings: updatedTime }
                    };
                }
            }
            return show;
        });
        setShows(updatedShows);
    }

    const handleShowChildMemberIncrement = (id) => {

        const updatedShows = shows.map(show => {
            if (show.location_id === id && show.ChildMemberBooking < childMember) {
                const updatedTime = show.selectedWorkingHours.timings.map(timeSlot => {
                    if (timeSlot.active) {
                        return { ...timeSlot, members: timeSlot.members + 1 };
                    }
                    return timeSlot;
                });
                const check = updatedTime.find((item) => item?.booked === true);
                if (check.members <= +check.available) {
                    return {
                        ...show,
                        ChildMemberBooking: show.ChildMemberBooking + 1,
                        selectedWorkingHours: { ...show.selectedWorkingHours, timings: updatedTime }
                    };
                }
            }
            return show;
        });
        setShows(updatedShows);
    }

    const handleShowChildMemberDecrement = (id) => {
        const updatedShows = shows.map(show => {
            if (show.location_id === id && show.ChildMemberBooking > 0) {
                const updatedTime = show.selectedWorkingHours.timings.map(timeSlot => {
                    if (timeSlot.active) {
                        console.log("timeSlot.members", timeSlot.members)
                        return { ...timeSlot, members: timeSlot.members - 1 };
                    }
                    return timeSlot;
                });
                const check = updatedTime.find((item) => item?.booked === true);
                if (check.members <= +check.available) {
                    return {
                        ...show,
                        ChildMemberBooking: show.ChildMemberBooking - 1,
                        selectedWorkingHours: { ...show.selectedWorkingHours, timings: updatedTime }
                    };
                }
                console.log("updatedTime", updatedTime);
            }
            return show;
        });
        setShows(updatedShows);
    }


    const handleShowForeignerMemberIncrement = (id) => {
        const updatedShows = shows.map(show => {
            if (show.location_id === id && show.ForeignerMemberBooking < foreignerMember) {
                const updatedTime = show.selectedWorkingHours.timings.map(timeSlot => {
                    if (timeSlot.active) {
                        return { ...timeSlot, members: timeSlot.members + 1 };
                    }
                    return timeSlot;
                });
                const check = updatedTime.find((item) => item?.booked === true);
                if (check.members <= +check.available) {
                    return {
                        ...show,
                        ForeignerMemberBooking: show.ForeignerMemberBooking + 1,
                        selectedWorkingHours: { ...show.selectedWorkingHours, timings: updatedTime }
                    };
                }
            }
            return show;
        });
        setShows(updatedShows);
    }

    const handleShowForeignerMemberDecrement = (id) => {
        const updatedShows = shows.map(show => {
            if (show.location_id === id && show.ForeignerMemberBooking > 0) {
                const updatedTime = show.selectedWorkingHours.timings.map(timeSlot => {
                    if (timeSlot.active) {
                        console.log("timeSlot.members", timeSlot.members)
                        return { ...timeSlot, members: timeSlot.members - 1 };
                    }
                    return timeSlot;
                });
                const check = updatedTime.find((item) => item?.booked === true);
                if (check.members <= +check.available) {
                    return {
                        ...show,
                        ForeignerMemberBooking: show.ForeignerMemberBooking - 1,
                        selectedWorkingHours: { ...show.selectedWorkingHours, timings: updatedTime }
                    };
                }
                console.log("updatedTime", updatedTime)
            }
            return show;
        });
        setShows(updatedShows);
    }

    const handleShowBook = (id, type, data) => {

        if (type === "add") {
            const date = formatShowSelectedDate(selectedDate)
            const bookesShow = { ...data, showdateObj: date }
            const updatedShows = shows.map(show => {
                if (show.location_id === id) {

                    if (!show?.AdultMemberBooking && !show?.ChildMemberBooking && !show.ForeignerMemberBooking) {
                        return { ...show, memberFieldsRequired: true };
                    } else if (show.AdultMemberBooking === 0 && show.ForeignerMemberBooking === 0) {
                        return { ...show, memberFieldsRequired: true };
                    } else {
                        setMemberBookShows([...memberBookShows, bookesShow])
                        return { ...show, memberFieldsRequired: false, AdultMemberBooking: 0, ChildMemberBooking: 0, ForeignerMemberBooking: 0, bookingStatus: true, showDate: selectedDate, addMembers: false, selectTiming: show.selectedWorkingHours.timings, showBookingButton: true }; // Add AdultMemberBooking key
                    }
                }
                return show;
            });
            setShows(updatedShows);

        } else {
            const updatedShows = shows.map(show => {
                if (show.location_id === id) {
                    const updatedTimings = show.selectedWorkingHours.timings.map((item) => {
                        console.log("show show", show.starttime)
                        if (item.starttime === show.selectedStartTiming) {
                            return {
                                ...item,
                                active: false,
                                booked: false,
                                members: 0
                            };
                        }
                        return item;
                    });
                    show.selectedWorkingHours.timings = updatedTimings;
                    return { ...show, bookingStatus: false, showDate: selectedDate, addMembers: false, AdultMemberBooking: 0, ChildMemberBooking: 0, ForeignerMemberBooking: 0 };
                }
                return show;
            });

            console.log("updatedShows", updatedShows)
            setShows(updatedShows)
        }

    }

    const handleShowBooking = (workingHours, id) => {


        if (!addedEntryPassMember) {
            setBookingShowError("Please add entry pasess");
            window.scrollTo({
                top: 100,
                behavior: 'smooth' // This makes the scrolling smooth
            });
        } else {
            handleShowTiming(workingHours, id)
        }


    }


    useEffect(() => {
        // setUserModalShow(true)
        document.body.classList.add('bodygraycolor');
        // Cleanup function
        return () => {
            document.body.classList.remove('bodygraycolor');
        };
    }, []);

    const handleuserdetails = async (data) => {
        console.log("data", data)

        const formdata = new FormData();
        formdata.append("first_name", data.userName);
        formdata.append("email", data.userEmail);
        formdata.append("phone", data.phone);
        formdata.append("language_id", "1");

        try {
            const res = await apiAxios.post("registration/create_anonymous_user", formdata);
            setUserDetails(res.data);
            setUserModalShow(false)
        } catch (err) {
            console.log("err", err)
        }
    }

    const handleRemoveTicket = (item) => {

        let showsData = memberBookShows.filter((show) => show.location_id !== item.location_id || show.selectedtiming !== item.selectedtiming);
        let getShows = shows.find((ele) => ele.location_id === item.location_id);


        const updatedTimings = getShows.selectedWorkingHours.timings.map((show) => {
            console.log("show show", show.starttime)
            if (show.starttime === item.selectedStartTiming) {
                return {
                    ...show,
                    active: false,
                    booked: false,
                    members: 0
                };
            }
            return show;
        });

        getShows.selectedWorkingHours.timings = updatedTimings

        const updatedShows = shows.map((item) => {
            if (item.location_id === getShows.location_id) {
                return getShows
            }
            return item;
        })

        setShows(updatedShows);
        setMemberBookShows(showsData)
    }

    const handleShowTicketToPay = async () => {

        if (userDetails === null) {
            setUserDetailsProceedModals(true);
        } else {
            let data = shows.filter((item) => item.bookingStatus == true);


            const parsedDate = moment(selectedDate, "DD MMM YYYY");
            const formattedDate = parsedDate.format("YYYY-MM-DD");
            const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');

            const addOns = memberBookShows.map(item => ({ location_id: item.location_id, ticket_fee_details: item.ticket_fee_details[0], web_icon: item.web_icon, location_name: item.location_name, description: item.description, timing: item.selectedtiming, child_count: item.ChildMemberBooking, adult_count: item.AdultMemberBooking, foreigner_count: item.ForeignerMemberBooking }))
            const objApi = {
                "booking_datetime": currentTime,
                "event_date": formattedDate,
                "entry_details": {
                    "senior_citizen": 0,
                    "adult": adultMember,
                    "child": childMember,
                    "foreigner": foreignerMember,
                },
                "add_ons": addOns,
                userDetails: userDetails
            };

            const storageObj = {
                shows: shows,
                memberBookShows: memberBookShows,
                selectedDate: selectedDate,
                addedEntryPassMember: addedEntryPassMember,
                adultMember: adultMember,
                childMember: childMember,
                foreignerMember: foreignerMember,
                formatedDates: formatedDates,
                userDetails: userDetails,
                entryPassdateFormat: entryPassdateFormat
            }
            const objString = JSON.stringify(storageObj);
            const objApiString = JSON.stringify(objApi);
            localStorage.setItem('bookingData', objString);
            localStorage.setItem('objApiData', objApiString);

            navigate(`/procced-to-pay?type=booking`);


            // try {

            //     const result = await bookingApi.post(`${userDetails.user_id}/bookings`, objApi)
            //     console.log(" result", result)
            //     navigate(`/procced-to-pay?user_id=${userDetails.user_id}&booking_id=${result.data.booking_id}`);
            // } catch (err) {
            //     console.log(err)
            // }
        }
    }

    const handleEntryPassProccedToPay = async () => {
        setEntryPassModalShow(false)
        const parsedDate = moment(selectedDate, "DD MMM YYYY");
        const formattedDate = parsedDate.format("YYYY-MM-DD");

        const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
        const objApi = {
            "booking_datetime": currentTime,
            "event_date": formattedDate,
            "entry_details": {
                "senior_citizen": 0,
                "adult": adultMember,
                "child": childMember,
                "foreigner": foreignerMember,
            },
            userDetails: userDetails,
            "add_ons": []
        };
        //         const storageObj = {
        //             shows: shows,
        //             selectedDate: selectedDate,
        //             addedEntryPassMember: addedEntryPassMember,
        //             adultMember: adultMember,
        //             childMember: childMember,
        //             foreignerMember: foreignerMember,
        //             formatedDates: formatedDates,
        //             userDetails: userDetails,
        //             entryPassdateFormat: entryPassdateFormat
        //         }
        //         const objString = JSON.stringify(storageObj);
        //         localStorage.setItem('EntryPassData', objString);
        // //  localStorage.setItem('bookingData', objString);
        //         const objApiString = JSON.stringify(objApi);
        // localStorage.setItem('objApiData', objApiString);


        const storageObj1 = {
            shows: shows,
            memberBookShows: [],
            selectedDate: selectedDate,
            addedEntryPassMember: addedEntryPassMember,
            adultMember: adultMember,
            childMember: childMember,
            foreignerMember: foreignerMember,
            formatedDates: formatedDates,
            userDetails: userDetails,
            entryPassdateFormat: entryPassdateFormat
        }
        const objString1 = JSON.stringify(storageObj1);
        console.log("objString1", objString1)
        localStorage.setItem('bookingData', objString1);

        navigate(`/procced-to-pay?type=booking`);

        // try {

        //     const result = await bookingApi.post(`${userDetails.user_id}/bookings`, objApi)
        //     console.log(" result", result)
        //     const objString = JSON.stringify(storageObj);
        //     localStorage.setItem('EntryPassData', objString);
        //     navigate(`/procced-to-pay?user_id=${userDetails.user_id}&booking_id=${result.data.booking_id}`);
        // } catch (err) {
        //     console.log("errerrerrerr", err.response.data.error);
        //     alert(err.response.data.error)
        // }
    }


    const handleDateChange = (date) => {
        const selectedDate = moment(date.toDate()).format('DD MMM YYYY');
        // const date1 = moment(date.toDate()).format('DD-MM-YYYY');
        const entryPassdateFormat = formatShowSelectedDate(selectedDate);
        setEntryPassdateFormat(entryPassdateFormat);

        const data = shows.map(item => ({
            ...item,
            selectedDate,
        }));
        setShows(data);
        setCalendarSelectedDate(date);
        setSelectedDate(selectedDate)
    };



    const handleAdutEntryPassIncrement = () => {
        setError("");
        setAdultMember(adultMember + 1);
    }

    const handleAdutEntryPassDecrement = () => {
        setError("");
        if (adultMember > 0) {
            setAdultMember(adultMember - 1);

        }
    }

    const handleChildEntryPassIncrement = () => {
        setError("");
        setChildMember(childMember + 1)
    }

    const handleChildEntryPassDecrement = () => {
        setError("");
        if (childMember > 0) {
            setChildMember(childMember - 1)

        }
    }

    const handleForeignerEntryPassIncrement = () => {
        setError("");
        setForeignerMember(foreignerMember + 1)
    }

    const handleForeignerEntryPassDecrement = () => {
        setError("");
        if (foreignerMember > 0) {
            setForeignerMember(foreignerMember - 1)

        }
    }

    const handleRemoveEntryPass = () => {

        const updatedTimings = shows.map((show) => {
            return { ...show, bookingStatus: false, selectedWorkingHours: null }
        })
        setShows(updatedTimings)
        setChildMember(0)
        setForeignerMember(0)
        setAdultMember(0);
        setAddedEntryPassMember(false)
        setMemberBookShows([])
        setPassBooking(true)
    }


    const handleUserSelectShowTiming = (data, startTime, endTime, available) => {
        const res = data.selectedWorkingHours.timings.map((item) => {
            return {
                ...item,
                active: item.starttime === startTime ? true : false,
                booked: item.starttime === startTime && true,
            };
        });

        const selectedtiming = `${startTime} to ${endTime}`;
        const userSelectedtiming = `${startTime} to ${endTime}`;
        const selectedStartTiming = `${startTime}`;

        const obj = {
            ...data,
            selectedtiming: selectedtiming,
            userSelectedtiming: userSelectedtiming,
            available, available,
            selectedStartTiming: selectedStartTiming,
            selectedWorkingHours: { ...data.selectedWorkingHours, timings: res }
        };
        const updatedShows = shows.map((item) => {
            if (item.location_id === obj.location_id) {
                return { ...item, openShowTimingSection: false, selectedtiming: obj.selectedtiming, available: obj.available, userSelectedtiming: obj.userSelectedtiming, selectedStartTiming: obj.selectedStartTiming, selectedWorkingHours: obj.selectedWorkingHours, selectedWorkingHoursCopy: obj.selectedWorkingHours, addMembers: true, showBookingButton: false }
            }
            return item;
        });
        setShows(updatedShows);

    }

    const handleShowTimingSection = (id) => {
        const updatedShows = shows.map((item) => {
            if (item.location_id === id && id) {
                return { ...item, openShowTimingSection: false }
            }
            return item;
        });
        setShows(updatedShows);

    }

    const handleGotToShow = () => {
        window.scrollTo({
            top: window.scrollY + 600,
            behavior: 'smooth' // This makes the scrolling smooth
        });
    }

    console.log("memberBookShows", memberBookShows)
    console.log("showsshowsshows", shows)






    const textContent = {
        en: {
            pageTitle: 'Buy ticket',
            HomePage: 'Home',
            SelectDate: 'Select Date',
            BookyourTxt: 'Book your tickets for Mahabhart Museum Only.',
            EntryPass : 'Entry Pass',
            MahabharatMuseum: 'Mahabharat Museum',
            Address: 'Address',
            Addressdetail: 'kurukshetra (Haryana)',
            MuseumTiming: 'Museum Timing',
            TuesdaySaturdayLabel: 'Tuesday – Saturday',
            SSTime: '10.am. - 5.pm.',
            SundayTimeLabel: 'Sunday',
            SundayTime: '11.am. - 5.pm',
            BookEntryPass: 'Book Entry Pass',
            Youmightalsolike: 'You might also like',            
            Shakuntla: 'Shakuntla',
            Lightandsoundshow: 'Light and sound show',
            SundayTime: '11.am. - 5.pm',
            Saraswati: 'Saraswati',
            MahabharatMahanayak: 'Mahabharat Mahanayak',
        },
        hi: {
            pageTitle: 'टिकट खरीदें',
            HomePage: 'मुख्य पृष्ठ',
            SelectDate: 'तारीख़ चुनें',
            BookyourTxt: 'केवल महाभारत संग्रहालय के लिए अपनी टिकट बुक करें।',
            EntryPass : 'प्रवेश पास',
            MahabharatMuseum: 'महाभारत संग्रहालय',
            Address: 'पता',
            Addressdetail: 'कुरुक्षेत्र (हरियाणा)',
            MuseumTiming: ' संग्रहालय का समय',
            TuesdaySaturdayLabel: 'मंगलवार - शनिवार',
            SSTime: 'सुबह 10 बजे - शाम 5 बजे',
            SundayTimeLabel: 'रविवार',
            SundayTime: 'सुबह 11 बजे - शाम 5 बजे',
            BookEntryPass: 'प्रवेश पास बुक करें',
            Youmightalsolike: 'अन्य शो',            
            Shakuntla: ' शकुंतला',
            Lightandsoundshow: ' लाइट एंड साउंड शो',
            SundayTime: '11.am. - 5.pm',
            Saraswati: 'सरस्वत',
            MahabharatMahanayak: 'महाभारत महानायक',
        },
    };



    return (
        <div>
            <div className="inner-pages-head" style={{ backgroundImage: `url('images/about-header-bg.jpg')` }}>
                <div className="container">
                    <div className="title_and_breadcrumbs">
                        <h1>{textContent[language].pageTitle}</h1>
                        <ul>
                            <li><Link to="/">{textContent[language].HomePage}</Link></li>
                            <span></span>
                            <li>{textContent[language].pageTitle}</li>
                        </ul>
                    </div>
                    <div className="banner-logo">
                        <img src="images/mahabharat-logo.svg" alt="" />
                    </div>
                </div>
            </div>

            <main className='mt-5'>

                <div className='container booking-page'>

                    <div className="select-date-wrapper">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            {textContent[language].SelectDate}
                            <DatePicker className='datepicker'
                                value={calendarSelectedDate}
                                onChange={handleDateChange}
                                format='DD-MM-YYYY'
                                minDate={dayjs()}
                                renderInput={(params) => <input {...params} />}
                            />
                        </LocalizationProvider>
                    </div>

                    <div className='row '>
                        <div className='mb-5 col-md-3 entry-pass'>
                            <h2>{textContent[language].EntryPass}</h2>
                            <p>{textContent[language].BookyourTxt}</p>
                            <button>{"₹ 0.0"}</button>
                        </div>
                        <div className="mb-5 col-md-9">
                            <div className="ticket-view">
                                <div className="left-ticket" style={{ backgroundImage: 'url(/images/entry-ticket-left-bg.svg)' }}>
                                    <div className="ticket-inner">
                                        <div className="ticket-details">
                                            <div className="details-item">
                                                <span>{textContent[language].EntryPass}</span>
                                                <h4 className="Museumname">{textContent[language].MahabharatMuseum}</h4>
                                            </div>
                                        </div>
                                        <div className="ticket-details">
                                            <div className="details-item">
                                                <span>{textContent[language].Address}</span>
                                                <h4 className="address">{textContent[language].Addressdetail}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ticket-inner">
                                        <div className="ticket-timing">
                                            <h4>{textContent[language].MuseumTiming}</h4>
                                            <ul>
                                                <li>{textContent[language].TuesdaySaturdayLabel} - &nbsp; <span>{textContent[language].SSTime}</span></li>
                                                <li>{textContent[language].SundayTimeLabel} - &nbsp; <span>{textContent[language].SundayTime}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="right-ticket" style={{ background: 'url(/images/entry-ticket-right-bg.svg)' }}>
                                    {
                                        !addedEntryPassMember ?

                                            <div className="book-now-view">
                                                {
                                                    !passBooking ?
                                                        <button className='Book-Your-Ticket' onClick={() => { setPassBooking(true); setBookingShowError(""); }}>{textContent[language].BookEntryPass}</button>
                                                        :
                                                        <form className='selectguests'>
                                                            <span>{formatCalendarDate}</span>
                                                            <div className='member-wrapp'>
                                                                <input type="number" readOnly className='Adults' min={0} value={adultMember} />
                                                                <div className='qnt-btns'>
                                                                    <button type='button' onClick={handleAdutEntryPassDecrement}>-</button>
                                                                    <button type='button' onClick={handleAdutEntryPassIncrement}>+</button>
                                                                </div>
                                                            </div>

                                                            <div className='member-wrapp'>
                                                                <input type="number" readOnly className='Children' min={0} value={childMember} />
                                                                <div className='qnt-btns'>
                                                                    <button type='button' onClick={handleChildEntryPassDecrement}>-</button>
                                                                    <button type='button' onClick={handleChildEntryPassIncrement}>+</button>
                                                                </div>
                                                            </div>

                                                            <div className='member-wrapp'>
                                                                <input type="number" readOnly className='Foreigner' min={0} value={foreignerMember} />
                                                                <div className='qnt-btns'>
                                                                    <button type='button' onClick={handleForeignerEntryPassDecrement}>-</button>
                                                                    <button type='button' onClick={handleForeignerEntryPassIncrement}>+</button>
                                                                </div>
                                                            </div>
                                                            {
                                                                error != "" && <div style={{ color: "red" }} className='error'>{error}</div>
                                                            }
                                                            <button className='add' type='button' onClick={handleAddMemberForPass}>Add</button>
                                                        </form>

                                                }
                                                {
                                                    bookingShowError !== "" && <div className='error selectyourentrypass' style={{ color: "red", textAlign: "center" }}>{bookingShowError}</div>
                                                }
                                            </div>
                                            :
                                            <div className="book-now-view">
                                                <form className='selectguests'>
                                                    <span>{formatCalendarDate}</span>
                                                    <div className='member-wrapp'>
                                                        <input type="number" readOnly className='Adults' min={0} value={adultMember} />
                                                        <div className='qnt-btns'>
                                                            <button type='button' onClick={handleAdutEntryPassDecrement}>-</button>
                                                            <button type='button' onClick={handleAdutEntryPassIncrement}>+</button>
                                                        </div>
                                                    </div>

                                                    <div className='member-wrapp'>
                                                        <input type="number" readOnly className='Children' min={0} value={childMember} />
                                                        <div className='qnt-btns'>
                                                            <button type='button' onClick={handleChildEntryPassDecrement}>-</button>
                                                            <button type='button' onClick={handleChildEntryPassIncrement}>+</button>
                                                        </div>
                                                    </div>

                                                    <div className='member-wrapp'>
                                                        <input type="number" readOnly className='Foreigner' min={0} value={foreignerMember} />
                                                        <div className='qnt-btns'>
                                                            <button type='button' onClick={handleForeignerEntryPassDecrement}>-</button>
                                                            <button type='button' onClick={handleForeignerEntryPassIncrement}>+</button>
                                                        </div>
                                                    </div>
                                                    {
                                                        error != "" && <div style={{ color: "red" }} className='error'>{error}</div>
                                                    }
                                                </form>
                                            </div>
                                    }
                                </div>



                            </div>
                        </div>

                        {
                            addedEntryPassMember && (
                                <div className='cart-strip elected-entry-pass'>
                                    <div className='booked-entryPass-section selected-tickets'>
                                        <div className='inner-wrap'>
                                            <div className=' date'>
                                                <span>{entryPassdateFormat?.day}</span>
                                                <span className='dateday'>{entryPassdateFormat?.date}</span>
                                                <span>{entryPassdateFormat?.month}</span>
                                            </div>
                                        </div>
                                        <div className='inner-wrap'>


                                            <div className='selected-ticket-item'>
                                                <span class="remove-ticket" onClick={handleRemoveEntryPass}>✕</span>
                                                <div className="img"><img src="/images/Entry-pass.jpg" alt="" /></div>
                                                <div className="ticket-item-details">
                                                    <i className='ShowSelected-Time'>
                                                        <svg className='showtime' width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.9998 6.99999V15.3333C16.9998 16.2538 17.746 17 18.6665 17L26.9998 17M16.9998 32.8333C8.25533 32.8333 1.1665 25.7445 1.1665 17C1.1665 8.25548 8.25533 1.16666 16.9998 1.16666C25.7443 1.16666 32.8332 8.25548 32.8332 17C32.8332 25.7445 25.7443 32.8333 16.9998 32.8333Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                        <div className='time-tooltip'>{formatCalendarDate}</div>
                                                    </i>
                                                    <h5 className='title'>Mahabharat Museum</h5>
                                                    <div className="qnt-andprice">

                                                        <ul>
                                                            <li>Adult <span>{adultMember}</span>,</li>
                                                            <li>Child <span>{childMember}</span>,</li>
                                                            <li>Foreigner <span>{foreignerMember}</span></li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                memberBookShows.length > 0 &&
                                                memberBookShows.map((item, index) => (
                                                    <div className='selected-ticket-item'>
                                                        <span class="remove-ticket" onClick={() => handleRemoveTicket(item)}>✕</span>
                                                        <div className="img"><img src={item?.web_icon} alt="" /></div>
                                                        <div className="ticket-item-details">
                                                            <i className='ShowSelected-Time'>
                                                                <svg className='showtime' width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M16.9998 6.99999V15.3333C16.9998 16.2538 17.746 17 18.6665 17L26.9998 17M16.9998 32.8333C8.25533 32.8333 1.1665 25.7445 1.1665 17C1.1665 8.25548 8.25533 1.16666 16.9998 1.16666C25.7443 1.16666 32.8332 8.25548 32.8332 17C32.8332 25.7445 25.7443 32.8333 16.9998 32.8333Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                </svg>
                                                                <div className='time-tooltip'>{item.selectedtiming || 0}</div>
                                                            </i>
                                                            <h5 className='title'>{item.location_name}</h5>
                                                            <div className="qnt-andprice">

                                                                <ul>
                                                                    <li>Adult <span>{item.AdultMemberBooking || 0}</span>,</li>
                                                                    <li>Child <span>{item.ChildMemberBooking || 0}</span>,</li>
                                                                    <li>Foreigner <span>{item.ForeignerMemberBooking || 0}</span></li>

                                                                </ul>
                                                                {/* <div className="price">₹ 799</div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </div>
                                    <div className='EntryPass-actions'>
                                        {
                                            memberBookShows.length === 0 && <button type='button' className='addShow' onClick={handleGotToShow}>+ Add Show</button>
                                        }
                                        <button
                                            type='button'
                                            className='proceedto-page'
                                            onClick={() => {
                                                if (userDetails === null) {
                                                    setUserModalShow(true);
                                                    setEntryPassModalShow(true)
                                                } else {
                                                    setEntryPassModalShow(true)
                                                }
                                            }}
                                        >
                                            Proceed to pay
                                        </button>
                                    </div>
                                </div>
                            )
                        }





                    </div>
                </div>

                {
                    loading ?
                        <BookingListSkelton count={4} />


                        :

                        shows?.length > 0 ?
                            <section className="all-tickets">

                                <div className="container">
                                    <h2 className="section-title">{textContent[language].Youmightalsolike}</h2>
                                </div>


                                <div className="container">
                                    <div className="row">
                                        {

                                            shows.map((item) => {

                                                console.log("itemitemitemitem", item)
                                                return (
                                                    <div className="col-lg-6 mb-4">
                                                        <div className="ticket-view-item">
                                                            <div className="img"><img src={item?.web_icon} alt="" /></div>
                                                            {
                                                                item?.bookingStatus && <div className='ticket-selected'>
                                                                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect x="1" y="1" width="31" height="31" rx="15.5" stroke="white" stroke-width="2" />
                                                                        <path d="M23 13L15.8276 20.2493C14.8373 21.2502 13.2317 21.2502 12.2414 20.2493L10 17.9839" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                </div>
                                                            }
                                                            <div className="ticket-details-area">
                                                                {
                                                                    // time !== undefined && <p>{`selected time`}</p>

                                                                }
                                                                <h3 className="title">{item.location_name}</h3>

                                                                <span className="date">{item.selectedDate}</span>
                                                                <p>{item.description}</p>
                                                                <ul className='priceSaprate'>
                                                                    <li className='Adult'><span>{"₹"} {item.ticket_fee_details[0].adult_fee || 0}</span></li>
                                                                    <li className='Child'><span>{"₹"} {item.ticket_fee_details[0].child_fee || 0}</span></li>
                                                                    <li className='Foreigner'><span>{"₹"} {item.ticket_fee_details[0].foreigner_fee || 0}</span></li>
                                                                </ul>
                                                                {
                                                                    item.addMembers && (
                                                                        <form className='selectguests'>
                                                                            <span className='selectedtime'>{`selected time`} : <i>{item.userSelectedtiming}</i></span>
                                                                            <span className='selectedtime'>{`Available`} : <i>{item.available}</i></span>
                                                                            <div className='input-grp'>
                                                                                <input type="number" className='Adult' min={0} value={item?.AdultMemberBooking === 0 ? "" : item?.AdultMemberBooking} readOnly />
                                                                                <div className='qnt-btns'>
                                                                                    <button type='button' onClick={() => handleShowAdultMemberDecrement(item.location_id)}>-</button>
                                                                                    <button type='button' onClick={() => handleShowAdultMemberIncrement(item.location_id)}>+</button>
                                                                                </div>
                                                                            </div>

                                                                            <div className='input-grp'>
                                                                                <input type="number" className='Children' min={0} value={item?.ChildMemberBooking === 0 ? "" : item?.ChildMemberBooking} readOnly />
                                                                                <div className='qnt-btns'>
                                                                                    <button type='button' onClick={() => handleShowChildMemberDecrement(item.location_id)}>-</button>
                                                                                    <button type='button' onClick={() => handleShowChildMemberIncrement(item.location_id)}>+</button>

                                                                                </div>
                                                                            </div>

                                                                            <div className='input-grp'>
                                                                                <input type="number" className='Foreigner' value={item?.ForeignerMemberBooking === 0 ? "" : item?.ForeignerMemberBooking} readOnly />
                                                                                <div className='qnt-btns'>
                                                                                    <button type='button' onClick={() => handleShowForeignerMemberDecrement(item.location_id)}>-</button>
                                                                                    <button type='button' onClick={() => handleShowForeignerMemberIncrement(item.location_id)}>+</button>

                                                                                </div>
                                                                            </div>

                                                                            {
                                                                                item.memberFieldsRequired && <div className='error' style={{ color: "red" }}>{"Atleast one member should be selected"}</div>
                                                                            }
                                                                            <div className='btn-grp'>
                                                                                <button type='button' className='book-ticket' onClick={() => handleShowBook(item.location_id, "cancel")}>Cancel</button>
                                                                                <button type='button' className='add' onClick={() => handleShowBook(item.location_id, "add", item)}>Add</button>
                                                                            </div>


                                                                        </form>

                                                                    )
                                                                }

                                                                <button className='book-ticket' onClick={() => handleShowBooking(item.working_hours, item.location_id)}>Book Now</button>

                                                                {
                                                                    item?.selectedWorkingHours !== null && item.openShowTimingSection && (
                                                                        <div className='selectTimePopup'>
                                                                            <span className='close' onClick={() => handleShowTimingSection(item.location_id)}>
                                                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M1 10L5.5 5.5M5.5 5.5L10 1M5.5 5.5L10 10M5.5 5.5L1 1" stroke="#7D7D7D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                            </span>
                                                                            <span className='day'>{item?.selectedWorkingHours?.weekday}</span>
                                                                            <ul>
                                                                                <li className={`${item?.selectedWorkingHours?.availability === "close" ? "disable" : ""}`}>
                                                                                    <div className="time-list">
                                                                                        {
                                                                                            item?.selectedWorkingHours?.timings?.map((ele) => (

                                                                                                <button
                                                                                                    type='button'
                                                                                                    disabled={ele.members > 0 ? true : false}
                                                                                                    className={`time-item ${ele?.active && ele.members === 0 ? 'active' : ''}`}
                                                                                                    onClick={() => handleUserSelectShowTiming(item, ele.starttime, ele.closetime, ele.available)}
                                                                                                >
                                                                                                    <span className={`time-stamp`} >{`${ele.starttime} to ${ele.closetime}`}</span>
                                                                                                </button>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    {
                                        memberBookShows.length > 0 &&
                                        <div className='cart-strip'>
                                            <div className="row align-items-center">
                                                <div className="col-md-9">
                                                    <div className='selected-tickets'>
                                                        {
                                                            memberBookShows.length > 0 && (
                                                                <>
                                                                    <div className='inner-wrap'>
                                                                        <div className=' date'>
                                                                            <span>{entryPassdateFormat.day}</span>
                                                                            <span className='dateday'>{entryPassdateFormat.date}</span>
                                                                            <span>{entryPassdateFormat.month}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className='inner-wrap'>


                                                                        <div className='selected-ticket-item'>
                                                                            <span class="remove-ticket" onClick={handleRemoveEntryPass}>✕</span>
                                                                            <div className="img"><img src="/images/Entry-pass.jpg" alt="" /></div>
                                                                            <div className="ticket-item-details">
                                                                                <i className='ShowSelected-Time'>
                                                                                    <svg className='showtime' width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M16.9998 6.99999V15.3333C16.9998 16.2538 17.746 17 18.6665 17L26.9998 17M16.9998 32.8333C8.25533 32.8333 1.1665 25.7445 1.1665 17C1.1665 8.25548 8.25533 1.16666 16.9998 1.16666C25.7443 1.16666 32.8332 8.25548 32.8332 17C32.8332 25.7445 25.7443 32.8333 16.9998 32.8333Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    </svg>
                                                                                    <div className='time-tooltip'>{formatCalendarDate}</div>
                                                                                </i>
                                                                                <h5 className='title'>Mahabharat Museum</h5>
                                                                                <div className="qnt-andprice">

                                                                                    <ul>
                                                                                        <li>Adult <span>{adultMember}</span>,</li>
                                                                                        <li>Child <span>{childMember}</span>,</li>
                                                                                        <li>Foreigner <span>{foreignerMember}</span></li>

                                                                                    </ul>
                                                                                    {/* <div className="price">₹ 799</div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                    {memberBookShows.map((item, index) => (
                                                                        <div className='inner-wrap' key={index}>

                                                                            <div className='selected-ticket-item'>
                                                                                <span class="remove-ticket" onClick={() => handleRemoveTicket(item)}>✕</span>
                                                                                <div className="img"><img src={item?.web_icon} alt="" /></div>
                                                                                <div className="ticket-item-details">
                                                                                    <i className='ShowSelected-Time'>
                                                                                        <svg className='showtime' width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M16.9998 6.99999V15.3333C16.9998 16.2538 17.746 17 18.6665 17L26.9998 17M16.9998 32.8333C8.25533 32.8333 1.1665 25.7445 1.1665 17C1.1665 8.25548 8.25533 1.16666 16.9998 1.16666C25.7443 1.16666 32.8332 8.25548 32.8332 17C32.8332 25.7445 25.7443 32.8333 16.9998 32.8333Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        </svg>
                                                                                        <div className='time-tooltip'>{item.selectedtiming || 0}</div>
                                                                                    </i>
                                                                                    <h5 className='title'>{item.location_name}</h5>
                                                                                    <div className="qnt-andprice">

                                                                                        <ul>
                                                                                            <li>Adult <span>{item.AdultMemberBooking || 0}</span>,</li>
                                                                                            <li>Child <span>{item.ChildMemberBooking || 0}</span>,</li>
                                                                                            <li>Foreigner <span>{item.ForeignerMemberBooking || 0}</span></li>

                                                                                        </ul>
                                                                                        {/* <div className="price">₹ 799</div> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="proceed-to-pay-btn-wrap">
                                                        <button className='proceed-to-pay' onClick={handleShowTicketToPay}>Proceed To Pay</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>


                            </section>
                            :
                            
                            <div className="noShowYet">
                               {"No shows yet"}
                            </div>
                            



                }


            </main>
            <EntryPassModal
                show={entryPassModalShow}
                onHide={() => setEntryPassModalShow(false)}
                handleEntryPassProccedToPay={handleEntryPassProccedToPay}
                selectedDate={selectedDate}
                memberBookShows={memberBookShows}
                addedEntryPassMember={addedEntryPassMember}
                adultMember={adultMember}
                childMember={childMember}
                foreignerMember={foreignerMember}
                formatedDates={formatedDates}
                entryPassdateFormat={entryPassdateFormat}
                shows={shows}
                userDetails={userDetails}

            />
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                selectedShowsWorkingHours={selectedShowsWorkingHours}
                shows={shows}
                handleSelectedhowTiming={handleSelectedhowTiming}
                currentLocationId={currentLocationId}
            />

            <UserDetailsModels
                show={userModalshow}
                handleuserdetails={handleuserdetails}
                onHide={() => {
                    setUserModalShow(false)
                    setEntryPassModalShow(false)
                }}
            />
            <UserDetailsProccedModels
                show={userDetailsProceedModals}
                onHide={() => setUserDetailsProceedModals(false)}
                selectedDate={selectedDate}
                memberBookShows={memberBookShows}
                addedEntryPassMember={addedEntryPassMember}
                adultMember={adultMember}
                childMember={childMember}
                foreignerMember={foreignerMember}
                formatedDates={formatedDates}
                entryPassdateFormat={entryPassdateFormat}
                shows={shows}
                setUserDetails={setUserDetails}
            />





        </div>
    )
}

export default BookingPage


const MyVerticallyCenteredModal = React.memo((props) => {
    const [data, setData] = useState(null);



    useEffect(() => {
        let arr = props.shows;
        console.log("arr", arr)
        const res = arr.find((item) => item.location_id === props.currentLocationId);
        setData(res)
    }, [props.shows]);

    console.log("datadata", data)


    const handleSelectShowTiming = (startTime, endTime, available) => {
        if (data.selectedWorkingHours !== null) {
            const res = data.selectedWorkingHours.timings.map((item) => {
                return {
                    ...item,
                    active: item.starttime === startTime ? true : false,
                    booked: item.starttime === startTime && true,
                };
            });

            const selectedtiming = `${startTime} to ${endTime}`;
            const userSelectedtiming = `${startTime} to ${endTime}`;
            const selectedStartTiming = `${startTime}`;

            const updatedData = { ...data, selectedtiming: selectedtiming, userSelectedtiming: userSelectedtiming, available, available, selectedStartTiming: selectedStartTiming, selectedWorkingHours: { ...data.selectedWorkingHours, timings: res } };
            setData(updatedData);
            props.handleSelectedhowTiming(updatedData);
        }
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Show Timing
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="timing-table">
                    <ul>
                        {
                            data?.selectedWorkingHours !== null && (
                                <li className={`${data?.selectedWorkingHours?.availability === "close" ? "disable" : ""}`}>
                                    <span className='day'>{data?.selectedWorkingHours?.weekday}</span>
                                    <div className="time-list">
                                        {
                                            data?.selectedWorkingHours?.timings?.map((ele) => (

                                                <button type='button' disabled={ele.members > 0 ? true : false} className={`time-item ${ele?.active && ele.members === 0 ? 'active' : ''}`} onClick={() => handleSelectShowTiming(ele.starttime, ele.closetime, ele.available)}>
                                                    <span className={`time-stamp`} >{`${ele.starttime} to ${ele.closetime}`}</span>
                                                </button>
                                            ))
                                        }
                                    </div>

                                </li>
                            )

                        }
                    </ul>
                </div>
            </Modal.Body>
        </Modal>
    );
})

function EntryPassModal(props) {
    const navigate = useNavigate();
    const handleClickNo = () => {
        const parsedDate = moment(props.selectedDate, "DD MMM YYYY");
        const formattedDate = parsedDate.format("YYYY-MM-DD");

        const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
        const objApi = {
            "booking_datetime": currentTime,
            "event_date": formattedDate,
            "entry_details": {
                "senior_citizen": 0,
                "adult": props.adultMember,
                "child": props.childMember,
                "foreigner": props.foreignerMember,
            },
            userDetails: props.userDetails,
            "add_ons": []
        };
        //         const storageObj = {
        //             shows: shows,
        //             selectedDate: selectedDate,
        //             addedEntryPassMember: addedEntryPassMember,
        //             adultMember: adultMember,
        //             childMember: childMember,
        //             foreignerMember: foreignerMember,
        //             formatedDates: formatedDates,
        //             userDetails: userDetails,
        //             entryPassdateFormat: entryPassdateFormat
        //         }
        //         const objString = JSON.stringify(storageObj);
        //         localStorage.setItem('EntryPassData', objString);
        // //  localStorage.setItem('bookingData', objString);
        //         const objApiString = JSON.stringify(objApi);
        // localStorage.setItem('objApiData', objApiString);


        const storageObj = {
            shows: props.shows,
            memberBookShows: [],
            selectedDate: props.selectedDate,
            addedEntryPassMember: props.addedEntryPassMember,
            adultMember: props.adultMember,
            childMember: props.childMember,
            foreignerMember: props.foreignerMember,
            formatedDates: props.formatedDates,
            userDetails: props.userDetails,
            entryPassdateFormat: props.entryPassdateFormat
        }
        const objString = JSON.stringify(storageObj);
        const objApiString = JSON.stringify(objApi);
        localStorage.setItem('bookingData', objString);
        localStorage.setItem('objApiData', objApiString);

        navigate(`/procced-to-pay?type=booking`);
    }
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='custom-mobal'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Show
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='alert-txt'>
                    Do you want to add <br />Show’s also?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button className='no' onClick={handleClickNo}>No</Button>
                <Button className='yes'
                    onClick={() => {
                        window.scrollTo({
                            top: window.scrollY + 600,
                            behavior: 'smooth' // This makes the scrolling smooth
                        });
                        props.onHide()
                    }}
                >
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
}


